import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCopyToClipboard, useTitle, useUpdateEffect } from 'react-use';
import { useRecoilValue } from 'recoil';

import { Address, getFormattedAddress } from '@appchoose/address';
import AddressView from '@appchoose/address-view';
import Alert from '@appchoose/alert';
import Button from '@appchoose/button';
import Icon from '@appchoose/icon';
import Loader from '@appchoose/loader';
import { toast } from '@appchoose/toast';
import { useAuth0 } from '@auth0/auth0-react';

import { BillingInfoView } from '../../components/billing-info-view/billing-info-view';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../components/dropdown-menu';
import { Admin } from '../../components/icons/admin';
import { Refund } from '../../components/icons/refund';
import { brandState } from '../../stores/brand';
import { activeSaleIdState, activeSaleState } from '../../stores/sales';
import { findContact, saleDefaultContact } from '../../types/choose-contact';
import { StoreRegion } from '../../types/generated';
import {
  FileExtension,
  useBillingExportQuery,
} from '../../types/generated-new';
import { BrandMatch } from '../../types/navigation';
import { getDefaultFiscalCountry } from '../../utils/address';
import { track } from '../../utils/analytics';
import { getIsInvoiceReady } from '../../utils/billing';
import { formatPrice } from '../../utils/currency';
import { isMissingBillingInformations } from '../../utils/sales';
import { AdminWithTooltip } from './admin-with-tooltip';
import {
  AnalyticsCard,
  AnalyticsCardContent,
  AnalyticsCardLabel,
  AnalyticsCardValue,
} from './analytics-card';
import { BillingPayments } from './billing-payments';
import { BillingErrors } from './billing-product-errors';
import { BillingSummary } from './billing-summary';
import { InvoiceInformation } from './invoice-information';
import { InvoiceLocked } from './invoice-locked';
import { useBillingState } from './use-billing-state';
import { useBilling } from './use-billing-stats';

export const BillingScreen: React.FC = () => {
  const { user } = useAuth0();
  const { search } = useLocation();
  const navigate = useNavigate();
  const { brandId = '' } = useParams<BrandMatch>();
  const { i18n, t } = useTranslation();

  const brand = useRecoilValue(brandState);
  const activeSale = useRecoilValue(activeSaleState);
  const activeSaleId = useRecoilValue(activeSaleIdState);

  const BILLING_INFOS =
    brand?.store === StoreRegion.Fr
      ? {
          name: 'Bianca',
          image: 'Bianca-Braganolo',
          email: 'facture@appchoose.io',
        }
      : {
          name: 'Mathis',
          image: 'Mathis-Breard',
          email: 'invoice@appchoose.io',
        };
  const CHOOSE_INFOS: Address & {
    siren: string;
    siret: string;
    tva: string;
  } =
    brand?.store === StoreRegion.Fr
      ? {
          name: 'CHOOSE SAS',
          street: '5 rue Pleyel',
          bp: '93200',
          city: 'Saint-Denis',
          country: 'France',
          countryCode: 'FR',
          siren: '812 243 996',
          siret: '81224399600046',
          tva: 'FR44812243996',
        }
      : {
          name: 'Choose APP LTD.',
          street: 'C/O Orbiss Inc\n1411 Broadway, fl16',
          city: 'New York',
          bp: 'NY 10018',
          country: 'United States of America',
          countryCode: 'US',
          siren: '812 243 996',
          siret: '81224399600046',
          tva: 'FR44812243996',
        };

  const {
    data: billing,
    isLoading: billingLoading,
    error: billingError,
  } = useBilling();
  const { data: billingState } = useBillingState();

  const getExportOrders = async () => {
    const result = await refetchExportOrders();

    if (result.data) {
      if (result.data.billingExport.fileUrl) {
        window.open(result.data.billingExport.fileUrl, '_self');
        toast.success(result.data.billingExport.fileUrl);
      } else {
        toast.success(t('orders.empty_result'));
      }
    }
  };

  const exportTypeBillingOptions = [
    {
      label: t('invoice.export_menu.download_csv'),
      value: FileExtension.Csv,
      onSelect: () => {
        setExportTypeBilling(FileExtension.Csv);
        getExportOrders();
      },
    },
    {
      label: t('invoice.export_menu.download_xls'),
      value: FileExtension.Xlsx,
      onSelect: () => {
        setExportTypeBilling(FileExtension.Xlsx);
        getExportOrders();
      },
    },
  ];
  const [exportTypeBilling, setExportTypeBilling] = useState(FileExtension.Csv);

  useTitle(`${brand?.name ?? ''} ~ Invoice`);

  const fiscalCountryCode = getDefaultFiscalCountry(
    brand?.billing_address?.country
  )?.code;

  const isMissingBillingInfos = isMissingBillingInformations(brand);

  const { isFetching: loadingExportOrders, refetch: refetchExportOrders } =
    useBillingExportQuery(
      {
        saleId: activeSaleId ?? '',
        input: {
          fileExtension: exportTypeBilling ?? FileExtension.Csv,
        },
      },
      {
        enabled: false,
      }
    );

  const [clipboardState, copyToClipboard] = useCopyToClipboard();

  const onBillingAddressClick = async () => {
    const address = await getFormattedAddress(
      {
        company: CHOOSE_INFOS.name,
        address1: CHOOSE_INFOS.street,
        address2: '',
        zip: CHOOSE_INFOS.bp,
        city: CHOOSE_INFOS.city,
        country: 'FR',
      },
      i18n.language === 'fr' ? 'FR' : 'EN'
    );
    copyToClipboard(address?.join('\n') || '');
    track('CopyToClipboard', {
      label: 'billing_address',
    });
  };

  useUpdateEffect(() => {
    //TODO: handle errors
    toast.success(t('clipboard_copied'));
  }, [clipboardState]);

  const goToBillingSettings = () => {
    navigate({
      pathname: `/${brandId}/settings/billing`,
      search: search,
    });
  };

  const isInternalUser = user?.['https://appchoose.io/claims/isInternalUser'];

  const saleContact = findContact(
    brand?.contact_name ?? undefined,
    saleDefaultContact
  );

  const isSubjectVat =
    brand?.store === StoreRegion.Fr &&
    fiscalCountryCode === 'FR' &&
    !!billing?.is_subject_to_vat;

  const hasBillingError =
    billingError ?? billing === null ?? billing?.has_error;
  const locale = i18n.language === 'fr' ? 'fr' : 'en';
  const isInvoiceReady = getIsInvoiceReady(billingState);

  return (
    <main className="relative flex-1 overflow-y-auto focus:outline-none">
      <div className="m-4 max-w-4xl space-y-10 md:m-10">
        <header>
          <h2 className="text-4xl font-bold leading-[3.75rem] text-gray-900">
            {t('sidebar.billing')}
          </h2>
        </header>
        {!isInvoiceReady ? (
          <div>
            <InvoiceLocked isSubjectVat={isSubjectVat} />
          </div>
        ) : null}
        {!isInvoiceReady && isInternalUser ? (
          <>
            <div className="h-px w-full bg-gray-100" />
            <div className="flex space-x-4 border border-gray-100 bg-[#fbfbfb] p-6">
              <Admin className="h-6 w-6 text-gray-900" />
              <div className="flex flex-col text-sm text-gray-700">
                <div className="font-bold">
                  {t('invoice.tooltip_admin_only')}
                </div>
                <div className="leading-5.5">
                  {t('invoice.entire_section_not_visible_to_the_brand')}
                </div>
              </div>
            </div>
          </>
        ) : null}
        {isInvoiceReady || isInternalUser ? (
          <div className="space-y-14">
            {isMissingBillingInfos ? (
              <section>
                <Alert
                  title={t('invoice.missing_billing_infos_title')}
                  icon="alertCircle"
                  appearance="error"
                  sideContent={
                    <Button
                      onClick={goToBillingSettings}
                      type="button"
                      appearance="outline"
                      className="border-red-350 hover:border-red-600 focus-visible:ring-2 focus-visible:ring-red-600/30"
                    >
                      {t('invoice.missing_billing_infos_button')}
                    </Button>
                  }
                >
                  <p className="m-0 text-sm text-gray-700">
                    {t('invoice.missing_billing_infos_title')}
                  </p>
                </Alert>
              </section>
            ) : null}
            <InvoiceInformation isSubjectVat={isSubjectVat} />
            <BillingPayments isSubjectVat={isSubjectVat} />

            <section>
              <div className="mb-4 flex justify-between">
                <h3 className="text-xl font-bold">{t('invoice.title')}</h3>
                <DropdownMenu>
                  <DropdownMenuTrigger className="flex shrink-0 items-center [&[data-state=open]_svg]:rotate-180">
                    <div className="flex items-center space-x-2">
                      <span className="text-sm font-semibold text-green-900">
                        {t('invoice.export_menu.label')}
                      </span>
                      <Icon
                        icon="arrowDown"
                        className="shrink-0 transition-all"
                      />
                    </div>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent
                    align="end"
                    sideOffset={8}
                    className="min-w-52 overflow-y-auto"
                    style={{
                      maxHeight:
                        'var(--radix-dropdown-menu-content-available-height)',
                    }}
                  >
                    {exportTypeBillingOptions.map((option) => (
                      <DropdownMenuItem
                        key={option.value}
                        onSelect={option.onSelect}
                        className="space-x-2"
                      >
                        <Icon icon="download" size="large" />
                        <p>{option.label}</p>
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
              {isInternalUser ? (
                <div className="mb-4 flex w-full flex-row gap-4">
                  <AnalyticsCard className="relative w-full">
                    <AdminWithTooltip className="absolute right-4 top-4" />
                    <AnalyticsCardContent>
                      <div className="flex space-x-2 text-gray-700">
                        <Icon icon="euroCircle" />
                        <AnalyticsCardLabel>
                          {t('invoice.total_operation_ttc')}
                        </AnalyticsCardLabel>
                      </div>
                      <AnalyticsCardValue>
                        {formatPrice(
                          (billing?.total_operation_ttc ?? 0) / 100,
                          locale,
                          activeSale?.currency?.toString()?.toUpperCase() ??
                            'EUR'
                        )}
                      </AnalyticsCardValue>
                    </AnalyticsCardContent>
                  </AnalyticsCard>

                  <AnalyticsCard className="relative w-full">
                    <AdminWithTooltip className="absolute right-4 top-4" />
                    <AnalyticsCardContent>
                      <div className="flex space-x-2 text-gray-700">
                        <Refund />
                        <AnalyticsCardLabel>
                          {t('invoice.total_refund_ttc')}
                        </AnalyticsCardLabel>
                      </div>
                      <AnalyticsCardValue>
                        {formatPrice(
                          (billing?.total_refund_ttc ?? 0) / 100,
                          locale,
                          activeSale?.currency?.toString()?.toUpperCase() ??
                            'EUR'
                        )}
                      </AnalyticsCardValue>
                    </AnalyticsCardContent>
                  </AnalyticsCard>
                </div>
              ) : null}
              {billingState?.forceEdit &&
              !billingState.canEdit &&
              !billingLoading ? (
                <Alert
                  size="small"
                  appearance="default"
                  title={t('invoice.amount_subject_to_change')}
                  icon="alert"
                  className="mb-3.5"
                  style={{ borderRadius: '8px' }}
                >
                  <p className="text-sm text-gray-700">
                    {t('invoice.orders_are_still_being_processed', {
                      withdrawalPeriod: billingState.withdrawalPeriod,
                    })}
                  </p>
                </Alert>
              ) : null}
              {billingLoading ? (
                <Loader className="mb-12 size-6" />
              ) : (
                <>
                  {hasBillingError ? (
                    <Alert
                      appearance="error"
                      role="alert"
                      icon="information"
                      title={t('invoice.invoice_calculation_error_title')}
                      className="mb-8"
                    >
                      <p className="m-0 text-sm text-gray-700">
                        {t('invoice.invoice_calculation_error')}
                      </p>
                    </Alert>
                  ) : null}
                  {isInternalUser ? <BillingErrors /> : null}
                  {!hasBillingError ||
                  (billing?.has_error && isInternalUser) ? (
                    <BillingSummary
                      isInternalUser={isInternalUser}
                      isSubjectVat={isSubjectVat}
                    />
                  ) : null}
                </>
              )}
              {loadingExportOrders && <Loader className="size-8" />}
            </section>
            <section>
              <h3 className="mb-4 text-xl font-bold">
                {t('invoice.choose_billing_infos_title')}
              </h3>
              <div className="justify-between space-x-0 space-y-4 md:flex md:space-x-4 md:space-y-0">
                <div className="w-full rounded-lg border border-gray-300 p-6">
                  <AddressView
                    label={t('address.label')}
                    address={CHOOSE_INFOS}
                    locale={i18n.language === 'fr' ? 'FR' : 'EN'}
                  />
                  <div className="mt-4 flex space-x-12">
                    {brand?.store === StoreRegion.Fr ? (
                      <>
                        <div>
                          <dt className="pb-1.5 text-xs font-semibold uppercase tracking-wider text-gray-500">
                            {t('billing.fields.siret.label')}
                          </dt>
                          <dd className="text-sm text-gray-700">
                            {CHOOSE_INFOS.siret}
                          </dd>
                        </div>

                        <div>
                          <dt className="pb-1.5 text-xs font-semibold uppercase tracking-wider text-gray-500">
                            {t('billing.fields.intracommunity_vat.label')}
                          </dt>
                          <dd className="text-sm text-gray-700">
                            {CHOOSE_INFOS.tva}
                          </dd>
                        </div>
                      </>
                    ) : null}
                  </div>
                  <button
                    type="button"
                    className="mt-6 text-xs font-bold text-green-900 focus-visible:ring-2 focus-visible:ring-gray-900/30"
                    onClick={onBillingAddressClick}
                  >
                    {t('copy')}
                  </button>
                </div>
                <div className="flex w-full flex-col justify-around space-y-6 rounded-lg border border-gray-300 p-6">
                  <div className="flex space-x-4">
                    {brand?.contact_name ? (
                      <img
                        src={`https://public.choose.app/avatars/80x80/${saleContact?.avatar}-80x80.png`}
                        srcSet={`https://public.choose.app/avatars/80x80/${saleContact?.avatar}-80x80@2x.png 2x,
                          https://public.choose.app/avatars/80x80/${saleContact?.avatar}-80x80@3x.png 3x`}
                        className="inline-block size-20 rounded-full ring-1 ring-gray-100"
                        alt={saleContact?.firstName}
                      />
                    ) : (
                      <div className="size-20 rounded-full bg-gray-500"></div>
                    )}
                    <div>
                      <div className="pb-1.5 text-xs font-semibold uppercase tracking-wider text-gray-500">
                        {t('invoice.sales_contact')}
                      </div>
                      <div className="text-base font-semibold">
                        {saleContact?.firstName}
                      </div>
                      <a
                        href={`mailto:${saleContact?.email}`}
                        className="text-sm text-gray-700"
                      >
                        {saleContact?.email}
                      </a>
                    </div>
                  </div>
                  <div className="flex space-x-4">
                    <img
                      src={`https://public.choose.app/avatars/80x80/${BILLING_INFOS.image}-80x80.png`}
                      srcSet={`https://public.choose.app/avatars/80x80/${BILLING_INFOS.image}-80x80@2x.png 2x,
                        https://public.choose.app/avatars/80x80/${BILLING_INFOS.image}-80x80@3x.png 3x`}
                      className="inline-block size-20 rounded-full ring-1 ring-gray-100"
                      alt={BILLING_INFOS.name}
                    />
                    <div>
                      <div className="pb-1.5 text-xs font-semibold uppercase tracking-wider text-gray-500">
                        {t('invoice.billing_contact')}
                      </div>
                      <div className="text-base font-semibold">
                        {BILLING_INFOS.name}
                      </div>
                      <a
                        href={`mailto:${BILLING_INFOS.email}`}
                        className="text-sm text-gray-700"
                      >
                        {BILLING_INFOS.email}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <h3 className="mb-4 text-xl font-bold">
                {t('invoice.brand_billing_infos_title')}
              </h3>
              <div className="min-w-[18.375rem] rounded-lg border border-gray-300 p-6">
                <BillingInfoView
                  isSiretActive={
                    fiscalCountryCode === 'FR' &&
                    brand?.store === StoreRegion.Fr
                  }
                  address={brand?.billing_address ?? undefined}
                  iban={brand?.iban ?? undefined}
                  intracommunityVat={brand?.intracommunity_vat ?? undefined}
                  isSubjectVat={brand && !brand.is_autoentrepreneur}
                  siret={brand?.siret ?? undefined}
                  taxId={brand?.tax_id ?? undefined}
                  onEditBankInfo={goToBillingSettings}
                  onEditBillingInfo={goToBillingSettings}
                  onEditIban={goToBillingSettings}
                />
              </div>
            </section>
          </div>
        ) : null}
      </div>
    </main>
  );
};

BillingScreen.displayName = 'BillingScreen';
