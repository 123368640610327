import {
  InfiniteData,
  UseInfiniteQueryOptions,
  UseMutationOptions,
  UseQueryOptions,
  UseSuspenseInfiniteQueryOptions,
  UseSuspenseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useSuspenseInfiniteQuery,
  useSuspenseQuery,
} from '@tanstack/react-query';

import { useFetchData } from '../hooks/use-fetcher';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: string; output: string };
  DateTime: { input: string; output: string };
  FloatPercentage: { input: number; output: number };
  Json: { input: any; output: any };
  NonEmptyString: { input: string; output: string };
  PositiveInteger: { input: number; output: number };
  Upload: { input: any; output: any };
  Void: { input: any; output: any };
};

/** **Address V2 (Address is already taken)** */
export type AddressV2 = {
  __typename?: 'AddressV2';
  /** **City** */
  city: Scalars['String']['output'];
  /** **Country** */
  country: Scalars['String']['output'];
  /** **Country code** */
  countryCode: Scalars['String']['output'];
  /** **County** */
  county?: Maybe<Scalars['String']['output']>;
  /** **Letters indicating which state the address is from** */
  state?: Maybe<Scalars['String']['output']>;
  /** **Street** */
  street: Scalars['String']['output'];
  /** **Street additional** */
  streetAdditional?: Maybe<Scalars['String']['output']>;
  /** **Zip code** */
  zipCode: Scalars['String']['output'];
};

export type Billing = {
  __typename?: 'Billing';
  agg_by_vat_rate: Array<BillingByVat>;
  errors: Array<ProductSoldError>;
  has_error: Scalars['Boolean']['output'];
  is_subject_to_vat: Scalars['Boolean']['output'];
  payment_info: BillingPayment;
  total_billing_ht: Scalars['Int']['output'];
  total_billing_ttc: Scalars['Int']['output'];
  total_commission_ht: Scalars['Int']['output'];
  total_commission_ttc: Scalars['Int']['output'];
  total_delivery_ht: Scalars['Int']['output'];
  total_delivery_ttc: Scalars['Int']['output'];
  total_operation_ht: Scalars['Int']['output'];
  total_operation_ttc: Scalars['Int']['output'];
  total_refund_count: Scalars['Int']['output'];
  total_refund_ht: Scalars['Int']['output'];
  total_refund_ttc: Scalars['Int']['output'];
  total_return_count: Scalars['Int']['output'];
  total_return_ht: Scalars['Int']['output'];
  total_return_ttc: Scalars['Int']['output'];
  total_sold_count: Scalars['Int']['output'];
  total_turnover_ht: Scalars['Int']['output'];
  total_turnover_ttc: Scalars['Int']['output'];
};

export type BillingByVat = {
  __typename?: 'BillingByVat';
  total_billing_ht: Scalars['Int']['output'];
  total_billing_ttc: Scalars['Int']['output'];
  total_commission_ht: Scalars['Int']['output'];
  total_commission_ttc: Scalars['Int']['output'];
  total_operation_ht: Scalars['Int']['output'];
  total_operation_ttc: Scalars['Int']['output'];
  total_vat: Scalars['Int']['output'];
  vat_rate: Scalars['String']['output'];
};

/** **Information about financial transactions** */
export type BillingPayment = {
  __typename?: 'BillingPayment';
  /** **Amount paid by financial team** */
  amount_paid: Scalars['Int']['output'];
  /** **Financial team set this invoice as full paid** */
  is_full_paid: Scalars['Boolean']['output'];
  /** **Date of last financial transaction** */
  last_payment_at?: Maybe<Scalars['DateTime']['output']>;
};

/** **Information about state of billing** */
export type BillingState = {
  __typename?: 'BillingState';
  /** **All claims have been fulfilled by the brand ?** */
  allClaimsFulfilled: Scalars['Boolean']['output'];
  /** **All orders have been received by the customer ?** */
  allOrdersDelivered: Scalars['Boolean']['output'];
  /** **All returns have been validated by the brand / bot auto ?** */
  allReturnsValidated: Scalars['Boolean']['output'];
  /** **Billing can be edited ?** */
  canEdit: Scalars['Boolean']['output'];
  /**
   * **Date that the billing will be accessible and editable**
   *
   * - > null if there are somes errors => see 'errors'
   */
  editableAt?: Maybe<Scalars['DateTime']['output']>;
  /** **List of errors that lock billing's edition** */
  errors: Array<BillingStateError>;
  /**
   * **Billing can be edited with errors ?**
   *
   * - > true if financial team accept to display billing to brand even though has errors
   */
  forceEdit: Scalars['Boolean']['output'];
  /** **Date that the sale will be closed** */
  saleEndsAt: Scalars['DateTime']['output'];
  /** **Sale status** */
  saleStatus: SaleStatus;
  /** **Customer withdrawal period (in days)** */
  withdrawalPeriod: Scalars['Int']['output'];
};

/** **Error information of the billing edition** */
export type BillingStateError = {
  __typename?: 'BillingStateError';
  /** **Error code of this issue** */
  code: BillingStateErrorCode;
  /** **Identifiant of order | return | claim that have an issue** */
  id: Scalars['String']['output'];
};

/** **List of errors type that lock billing edition** */
export enum BillingStateErrorCode {
  /** **Claim is unfulfilled** */
  ClaimUnfulfilled = 'CLAIM_UNFULFILLED',
  /** **Order is not yet delivered** */
  OrderNotDelivered = 'ORDER_NOT_DELIVERED',
  /** **Return is not yet validated** */
  ReturnNotValidated = 'RETURN_NOT_VALIDATED',
}

export type Brand = {
  __typename?: 'Brand';
  accept_contract?: Maybe<Scalars['Boolean']['output']>;
  account_number?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['Json']['output']>;
  admin?: Maybe<Scalars['Boolean']['output']>;
  api?: Maybe<Scalars['String']['output']>;
  api_token?: Maybe<Scalars['String']['output']>;
  bank_address?: Maybe<Scalars['Json']['output']>;
  beneficiary_name?: Maybe<Scalars['String']['output']>;
  contact_email?: Maybe<Scalars['String']['output']>;
  contact_name?: Maybe<Scalars['String']['output']>;
  contact_phone?: Maybe<Scalars['String']['output']>;
  delivery_services?: Maybe<Scalars['Json']['output']>;
  has_shipping_service?: Maybe<Scalars['Boolean']['output']>;
  has_shopify?: Maybe<Scalars['Boolean']['output']>;
  iban?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  intracommunity_vat?: Maybe<Scalars['String']['output']>;
  isReseller?: Maybe<Scalars['Boolean']['output']>;
  is_autoentrepreneur?: Maybe<Scalars['Boolean']['output']>;
  is_french?: Maybe<Scalars['Boolean']['output']>;
  max_delivery_delay?: Maybe<Scalars['Int']['output']>;
  min_delivery_delay?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  need_refresh_info: Scalars['Boolean']['output'];
  plugins?: Maybe<Plugins>;
  return_address?: Maybe<Scalars['Json']['output']>;
  routing_code?: Maybe<Scalars['String']['output']>;
  sales?: Maybe<Array<Maybe<Sale>>>;
  serverTime?: Maybe<Scalars['DateTime']['output']>;
  shipping_country?: Maybe<Scalars['String']['output']>;
  shipping_services?: Maybe<Scalars['Json']['output']>;
  shopify?: Maybe<ShopifyData>;
  shopify_url?: Maybe<Scalars['String']['output']>;
  siret?: Maybe<Scalars['String']['output']>;
  store: StoreRegion;
  swift_code?: Maybe<Scalars['String']['output']>;
  tax_id?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export enum BrandRole {
  Admin = 'ADMIN',
  Member = 'MEMBER',
}

/** **The buyer is the person who has been billed for the order** */
export type Buyer = {
  __typename?: 'Buyer';
  /** **Address** */
  address: AddressV2;
  /** **First name** */
  firstName: Scalars['String']['output'];
  /** **Full name ("first_name last_name")** */
  fullName: Scalars['String']['output'];
  /** **Last name** */
  lastName: Scalars['String']['output'];
  /** **Previous buyer if it has been updated** */
  previous?: Maybe<Buyer>;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

/** **Claim** */
export type Claim = {
  __typename?: 'Claim';
  /** **Message written by the seller when it accept the claim** */
  acceptedMessage?: Maybe<Scalars['String']['output']>;
  /** **Action chose by the seller when it has accepted the claim** */
  actionFromSeller?: Maybe<ClaimSellerAction>;
  /** **Date of the creation** */
  createdAt: Scalars['DateTime']['output'];
  /** **Message written by the seller when it decline the claim** */
  declinedMessage?: Maybe<Scalars['String']['output']>;
  /** **ID** */
  id: Scalars['ID']['output'];
  /** **Is accepted ?** */
  isAccepted: Scalars['Boolean']['output'];
  /** **Is declined ?** */
  isDeclined: Scalars['Boolean']['output'];
  /** **Was created by automatic flow ?** */
  isOpenedByBot: Scalars['Boolean']['output'];
  /** **Items** */
  items: Array<Item>;
  /** **Message written by the support team to the seller** */
  messageFromSupport?: Maybe<Scalars['String']['output']>;
  /** **Other reason label written by the support team when the reason is "OTHER"** */
  otherReasonLabel?: Maybe<Scalars['String']['output']>;
  /** **Proof URLs** */
  proofUrls: Array<Scalars['String']['output']>;
  /** **Reason** */
  reason: ClaimReason;
  /** **Reship action error reasons if the action is not available** */
  reshipActionErrorReasons: Array<ClaimReshipActionErrorReason>;
  /** **Latest update date** */
  updatedAt: Scalars['DateTime']['output'];
};

/** **Claim Reason** */
export enum ClaimReason {
  /** **The customer has received the parcel but is damaged** */
  DeliveredButDamaged = 'DELIVERED_BUT_DAMAGED',
  /** **The customer has received the parcel but is incomplete** */
  DeliveredButIncomplete = 'DELIVERED_BUT_INCOMPLETE',
  /** **The customer has received the parcel but is wrong (wrong or missing items)** */
  DeliveredButWrong = 'DELIVERED_BUT_WRONG',
  /** **The customer has received a digital coupon code but is not working** */
  DigitalCouponCodeNotWorking = 'DIGITAL_COUPON_CODE_NOT_WORKING',
  /** **The customer has not received the parcel and has been returned to the sender** */
  NotDeliveredAndReturnedToSender = 'NOT_DELIVERED_AND_RETURNED_TO_SENDER',
  /** **The customer has not received the parcel and the tracking is blocked** */
  NotDeliveredAndTrackingBlocked = 'NOT_DELIVERED_AND_TRACKING_BLOCKED',
  /** **The customer has not received the parcel and the tracking is delivered** */
  NotDeliveredAndTrackingDelivered = 'NOT_DELIVERED_AND_TRACKING_DELIVERED',
  /** **Other** */
  Other = 'OTHER',
}

/** **Claim reship action error reason** */
export enum ClaimReshipActionErrorReason {
  /** **There is no items to reship** */
  NoItems = 'NO_ITEMS',
  /** **The order has already been reshipped** */
  OrderAlreadyReshipped = 'ORDER_ALREADY_RESHIPPED',
  /** **The order is cancelled** */
  OrderCancelled = 'ORDER_CANCELLED',
  /** **Some of the items to reship are cancelled** */
  SomeItemsCancelled = 'SOME_ITEMS_CANCELLED',
  /** **Some of the items to reship are digital** */
  SomeItemsDigital = 'SOME_ITEMS_DIGITAL',
}

/** **Claim seller action (action chose by the seller when it has accepted the claim)** */
export enum ClaimSellerAction {
  /** **The seller has directy handled the claim with the customer** */
  DirectlyHandledWithTheCustomer = 'DIRECTLY_HANDLED_WITH_THE_CUSTOMER',
  /** **The seller decided to resend a new digital coupon code for the items** */
  ItemsDigitalCouponCodesResent = 'ITEMS_DIGITAL_COUPON_CODES_RESENT',
  /** **The seller decided to refund the items** */
  ItemsRefunded = 'ITEMS_REFUNDED',
  /** **The seller decided to reship the items** */
  ItemsReshipped = 'ITEMS_RESHIPPED',
}

/** **Currency ISO code** */
export enum CurrencyIso {
  /** **Euro** */
  Eur = 'eur',
  /** **US Dollar** */
  Usd = 'usd',
}

/** **Represents a range of date and time when the parcel is expected to arrive at the customer's location** */
export type DeliveryDateRange = {
  __typename?: 'DeliveryDateRange';
  /** **The creation date** */
  createdAt: Scalars['DateTime']['output'];
  /** **The latest delivery date and time when the parcel is expected to arrive at the customer's location** */
  end: Scalars['Date']['output'];
  /** **The earliest delivery date and time when the parcel is expected to arrive at the customer's location** */
  start: Scalars['Date']['output'];
};

/** **Billing statistics for a sale by day** */
export type HistogramElement = {
  __typename?: 'HistogramElement';
  /**
   * **Date of stats**
   * - > *format* : YYYY-MM-DD
   */
  date: Scalars['String']['output'];
  nb_distinct_orders?: Maybe<Scalars['Int']['output']>;
  total_billing_ht?: Maybe<Scalars['Int']['output']>;
  total_billing_ttc?: Maybe<Scalars['Int']['output']>;
  total_commission_ht?: Maybe<Scalars['Int']['output']>;
  total_commission_ttc?: Maybe<Scalars['Int']['output']>;
  total_operation_ht?: Maybe<Scalars['Int']['output']>;
  total_operation_ttc?: Maybe<Scalars['Int']['output']>;
  total_vat?: Maybe<Scalars['Int']['output']>;
};

/** **Invoice created** */
export type InvoiceCreated = {
  __typename?: 'InvoiceCreated';
  id: Scalars['ID']['output'];
};

/** **Invoice updated** */
export type InvoiceUpdated = {
  __typename?: 'InvoiceUpdated';
  id: Scalars['ID']['output'];
};

/** **Item** */
export type Item = {
  __typename?: 'Item';
  /** **Digital coupon code** */
  digitalCouponCode?: Maybe<Scalars['String']['output']>;
  /** **GTIN** */
  gtin?: Maybe<Scalars['String']['output']>;
  /** **ID** */
  id: Scalars['ID']['output'];
  /** **Image URL** */
  imageUrl: Scalars['String']['output'];
  /** **Is cancellable ?** */
  isCancellable: Scalars['Boolean']['output'];
  /** **Is cancelled ?** */
  isCancelled: Scalars['Boolean']['output'];
  /** **Is digital ?** */
  isDigital: Scalars['Boolean']['output'];
  /** **Is offered ?** */
  isOffered: Scalars['Boolean']['output'];
  /** **Is reshipped ?** */
  isReshipped: Scalars['Boolean']['output'];
  /** **A return has been generated for this item ?** */
  isReturnGenerated: Scalars['Boolean']['output'];
  /** **A return has been validated for this item ?** */
  isReturnValidated: Scalars['Boolean']['output'];
  /** **Is returnable ?** */
  isReturnable: Scalars['Boolean']['output'];
  /** **Name** */
  name: Scalars['String']['output'];
  /** **New digital coupon code** */
  newDigitalCouponCode?: Maybe<Scalars['String']['output']>;
  /** **Price bought by Choose** */
  priceBoughtByChoose: Price;
  /** **Price sold by Choose to the customer (in cents)** */
  priceSold: Price;
  /** **Product ID** */
  productId: Scalars['String']['output'];
  /** **Product variant ID** */
  productVariantId: Scalars['String']['output'];
  /** **Refund summary** */
  refundSummary: OrderLineRefundSummary;
  /** **Return reason code** */
  returnReasonCode?: Maybe<ItemReturnReasonCode>;
  /** **Return sub reason code** */
  returnSubReasonCode?: Maybe<ItemReturnSubReasonCode>;
  /** **Notifications sent to the customer about the item** */
  sentNotifications: Array<ItemSentNotification>;
  /** **Size** */
  size?: Maybe<Scalars['String']['output']>;
  /** **SKU** */
  sku?: Maybe<Scalars['String']['output']>;
  /** **Voucher expiration date if the item is digital** */
  voucherExpiresAt?: Maybe<Scalars['DateTime']['output']>;
  /** **Voucher marked as used date if the item is digital** */
  voucherMarkedAsUsedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** **Item return reason code** */
export enum ItemReturnReasonCode {
  /** **Better price** */
  BetterPrice = 'BETTER_PRICE',
  /** **Damaged product** */
  DamagedProduct = 'DAMAGED_PRODUCT',
  /** **Damaged shipping** */
  DamagedShipping = 'DAMAGED_SHIPPING',
  /** **Delay** */
  Delay = 'DELAY',
  /** **Description** */
  Description = 'DESCRIPTION',
  /** **Other** */
  Other = 'OTHER',
  /** **Picture** */
  Picture = 'PICTURE',
  /** **Quality** */
  Quality = 'QUALITY',
  /** **Size** */
  Size = 'SIZE',
  /** **Wrong article** */
  WrongArticle = 'WRONG_ARTICLE',
}

/** **Item return sub reason code** */
export enum ItemReturnSubReasonCode {
  /** **bad shape** */
  BadShape = 'BAD_SHAPE',
  /** **multi size bought** */
  MultiSize = 'MULTI_SIZE',
  /** **Other** */
  Other = 'OTHER',
  /** **too large** */
  TooLarge = 'TOO_LARGE',
  /** **too long** */
  TooLong = 'TOO_LONG',
  /** **too short** */
  TooShort = 'TOO_SHORT',
  /** **too tight** */
  TooTight = 'TOO_TIGHT',
}

/** **Item sent notification** */
export enum ItemSentNotification {
  /** **Voucher expires in 7 days** */
  VoucherExpiresIn_7Days = 'VOUCHER_EXPIRES_IN_7_DAYS',
  /** **Voucher expires in 30 days** */
  VoucherExpiresIn_30Days = 'VOUCHER_EXPIRES_IN_30_DAYS',
}

export type Mutation = {
  __typename?: 'Mutation';
  /** **Accept a claim with codes** */
  acceptClaimOnDigitalItemsWithCodes: OrderV2;
  /** **Accept a claim with message** */
  acceptClaimOnDigitalItemsWithMessage: OrderV2;
  /** **Accept a claim with refund** */
  acceptClaimWithRefund?: Maybe<Scalars['Void']['output']>;
  /** **Accept a claim with reship** */
  acceptClaimWithReship?: Maybe<Scalars['Void']['output']>;
  confirmCsv?: Maybe<Scalars['String']['output']>;
  removeCsv?: Maybe<Scalars['String']['output']>;
  resyncShopifyOrders?: Maybe<Scalars['Int']['output']>;
  revokeApiToken?: Maybe<Scalars['Boolean']['output']>;
  setInfoAsUpToDate?: Maybe<Scalars['String']['output']>;
  setUserRole?: Maybe<Scalars['String']['output']>;
  setupShopifyCredentials?: Maybe<ShopifyData>;
  translateClaim: TranslateResponse;
  updateSeller?: Maybe<Scalars['String']['output']>;
  updateShopifyInfo?: Maybe<UpdatedShopifyData>;
  updateStock?: Maybe<Scalars['String']['output']>;
  updateUserLanguage?: Maybe<Scalars['String']['output']>;
  updateWidget?: Maybe<Scalars['String']['output']>;
  uploadCsvFile?: Maybe<Scalars['String']['output']>;
};

export type MutationAcceptClaimOnDigitalItemsWithCodesArgs = {
  claimId: Scalars['ID']['input'];
  codes: Array<NewDigitalCouponCodeInput>;
  orderId: Scalars['ID']['input'];
};

export type MutationAcceptClaimOnDigitalItemsWithMessageArgs = {
  claimId: Scalars['ID']['input'];
  message: Scalars['String']['input'];
  orderId: Scalars['ID']['input'];
};

export type MutationAcceptClaimWithRefundArgs = {
  claimId: Scalars['ID']['input'];
  orderId: Scalars['ID']['input'];
};

export type MutationAcceptClaimWithReshipArgs = {
  claimId: Scalars['ID']['input'];
  orderId: Scalars['ID']['input'];
};

export type MutationConfirmCsvArgs = {
  saleId: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type MutationRemoveCsvArgs = {
  saleId: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type MutationResyncShopifyOrdersArgs = {
  saleId: Scalars['String']['input'];
};

export type MutationSetUserRoleArgs = {
  role: BrandRole;
  userId: Scalars['String']['input'];
};

export type MutationSetupShopifyCredentialsArgs = {
  brandId: Scalars['String']['input'];
  data: ShopifyCredentialsInput;
};

export type MutationTranslateClaimArgs = {
  claimId: Scalars['ID']['input'];
  to: Scalars['String']['input'];
};

export type MutationUpdateSellerArgs = {
  updateSeller: UpdateSeller;
};

export type MutationUpdateShopifyInfoArgs = {
  data: UpdateShopifyInfoInput;
};

export type MutationUpdateStockArgs = {
  saleId: Scalars['String']['input'];
  stock?: InputMaybe<Scalars['Int']['input']>;
  stockId: Scalars['String']['input'];
};

export type MutationUpdateUserLanguageArgs = {
  preferredLanguage: Scalars['String']['input'];
};

export type MutationUpdateWidgetArgs = {
  data: UpdateWidget;
  id: Scalars['String']['input'];
};

export type MutationUploadCsvFileArgs = {
  saleId: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

/** **New digital coupon code input** */
export type NewDigitalCouponCodeInput = {
  /** **Current code** */
  current: Scalars['String']['input'];
  /** **New code** */
  new: Scalars['String']['input'];
};

/** **Operation type** */
export enum OperationType {
  /** **Choose with StockAZ clears its stock ** */
  Clearance = 'CLEARANCE',
  /** **The seller sell its products and ship on its own** */
  DropShipping = 'DROP_SHIPPING',
  /** **Choose has ordered the products before the operation and ship on its own** */
  FirmPurchase = 'FIRM_PURCHASE',
  /** **Choose order the products sold after the operation and ship it on its own** */
  PostOperationPurchase = 'POST_OPERATION_PURCHASE',
  /** **The reseller sell products from another seller => Not used anymore** */
  Reseller = 'RESELLER',
}

export type Option = {
  __typename?: 'Option';
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  values?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

/** **Order created** */
export type OrderCreated = {
  __typename?: 'OrderCreated';
  id: Scalars['ID']['output'];
};

/** **Order integration** */
export type OrderIntegration = {
  __typename?: 'OrderIntegration';
  /** **Error message from the integration (Shopify, StockAZ)** */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** **ID of the order from the integration (Shopify, StockAZ)** */
  orderId?: Maybe<Scalars['ID']['output']>;
  /** **Shopify integration** */
  shopify?: Maybe<ShopifyOrderIntegration>;
};

/** **Order line refund** */
export type OrderLineRefund = {
  __typename?: 'OrderLineRefund';
  /** **Amount in cents of the refund** */
  amountInCents: Scalars['PositiveInteger']['output'];
  /** **Creation date of the refund** */
  createdAt: Scalars['DateTime']['output'];
  /** **Identifier of the refund** */
  id: Scalars['ID']['output'];
  /** **Identifier of the line (item id for "ITEM" and order id for "SHIPPING" and "LEGACY")** */
  lineId: Scalars['ID']['output'];
  /** **Line type ("ITEM" or "SHIPPING")** */
  lineType: OrderLineType;
  /** **Identifier of the plan (refunds made at the same time have the same plan id)** */
  planId: Scalars['ID']['output'];
};

/** **Order line refund summary** */
export type OrderLineRefundSummary = {
  __typename?: 'OrderLineRefundSummary';
  /**
   * **Is refundable ?**
   *
   * Is not offered and not removed and has a price greater than 0
   */
  isRefundable: Scalars['Boolean']['output'];
  /**
   * **Is totally refunded ?**
   *
   * Has at least one refund and the refunded amount is equal to the price
   */
  isTotallyRefunded: Scalars['Boolean']['output'];
  /** **Refunded amount of the price in cents** */
  refundedAmountInCents: Scalars['PositiveInteger']['output'];
  /**
   * **Refunded amount of the price in percentage**
   *
   * Is between `0` and `100`
   */
  refundedAmountInPercentage: Scalars['FloatPercentage']['output'];
  /**
   * **Refunded amount of the price in percentage covered by Choose**
   *
   * Is between `0` and `100`
   */
  refundedAmountInPercentageCoveredByChoose: Scalars['FloatPercentage']['output'];
  /**
   * **Refunded amount of the price in percentage covered by the supplier**
   *
   * Is between `0` and `100`
   */
  refundedAmountInPercentageCoveredBySupplier: Scalars['FloatPercentage']['output'];
  /** **Remaining to refund amount of the price in cents** */
  remainingToRefundAmountInCents: Scalars['PositiveInteger']['output'];
  /**
   * **Remaining to refund amount of the price in percentage**
   *
   * Is between `0` and `100`
   */
  remainingToRefundAmountInPercentage: Scalars['FloatPercentage']['output'];
};

/** **Order line type** */
export enum OrderLineType {
  /** **Item** */
  Item = 'ITEM',
  /** **Legacy (not associated to an item or a shipping)** */
  Legacy = 'LEGACY',
  /** **Shipping** */
  Shipping = 'SHIPPING',
}

/** **Order payment** */
export type OrderPayment = {
  __typename?: 'OrderPayment';
  /** **Processor used for the payment** */
  processor: PaymentProcessor;
  /** **Processor detailed (eg: "stripe (ios Apple Pay)")** */
  processorDetailed: Scalars['String']['output'];
  /** **Processor transaction id** */
  processorTransactionId: Scalars['String']['output'];
  /** **Url of the transaction in the processor dashboard** */
  processorTransactionUrl: Scalars['String']['output'];
  /** **Sub processor used for the payment** */
  subProcessor?: Maybe<PaymentSubProcessor>;
  /** **Currency used for the payment transaction** */
  transactionCurrency: CurrencyIso;
};

/** **Order rating** */
export type OrderRating = {
  __typename?: 'OrderRating';
  /** **Message** */
  message?: Maybe<Scalars['String']['output']>;
  /** **Rate (between 1 and 5)** */
  rate: Scalars['Int']['output'];
};

/** **Order shipping** */
export type OrderShipping = {
  __typename?: 'OrderShipping';
  /** **Delivery date range history (ordered by createdAt desc)** */
  deliveryDateRangeHistory: Array<DeliveryDateRange>;
  /** **Estimated time in days** */
  estimatedTime: Scalars['Int']['output'];
  /** **Fees (in cents)** */
  fees: Scalars['Int']['output'];
  /** **Fees sold by Choose to the customer (in cents)** */
  feesSold: Scalars['Int']['output'];
  /** **Pickup point** */
  pickupPoint?: Maybe<PickupPoint>;
  /** **Policy ID** */
  policyId?: Maybe<Scalars['ID']['output']>;
  /** **Refund summary** */
  refundSummary: OrderLineRefundSummary;
};

/** **Order split action error reason** */
export enum OrderSplitActionErrorReason {
  /** **The order has all the items digital** */
  AllItemsDigital = 'ALL_ITEMS_DIGITAL',
  /** **The order is cancelled** */
  OrderCancelled = 'ORDER_CANCELLED',
  /** **The order has been reshipped** */
  OrderReshipped = 'ORDER_RESHIPPED',
  /** **The order has some claims pending** */
  SomeClaimsPending = 'SOME_CLAIMS_PENDING',
  /** **The order has some refunds** */
  SomeRefunds = 'SOME_REFUNDS',
  /** **The order has some return parcels** */
  SomeReturnParcels = 'SOME_RETURN_PARCELS',
  /** **The order has some return slips** */
  SomeReturnSlips = 'SOME_RETURN_SLIPS',
}

/** **Order tag brand** */
export enum OrderTagBrand {
  /** **The order is available for the brand and requires an action** */
  ActionRequired = 'ACTION_REQUIRED',
  /** **The order is available for the brand and requires an action because claim** */
  ActionRequiredClaim = 'ACTION_REQUIRED_CLAIM',
  /** **The order is available for the brand and requires an action because return** */
  ActionRequiredReturn = 'ACTION_REQUIRED_RETURN',
  /** **The order is available for the brand and requires an action because shipment incident** */
  ActionRequiredShipmentIncident = 'ACTION_REQUIRED_SHIPMENT_INCIDENT',
  /** **The order is available for the brand and requires an action because unfulfilled** */
  ActionRequiredUnfulfilled = 'ACTION_REQUIRED_UNFULFILLED',
  /** **The order is available for the brand** */
  Available = 'AVAILABLE',
}

/** **Order tag claim** */
export enum OrderTagClaim {
  /** **The order has some closed claims** */
  Closed = 'CLOSED',
  /** **The order has no claim** */
  None = 'NONE',
  /** **The order has some opened claims** */
  Opened = 'OPENED',
}

/** **Order tag claim reason** */
export enum OrderTagClaimReason {
  /** **The order has some claims with delivered but damaged reason** */
  DeliveredButDamaged = 'DELIVERED_BUT_DAMAGED',
  /** **The order has some claims with delivered but incomplete reason** */
  DeliveredButIncomplete = 'DELIVERED_BUT_INCOMPLETE',
  /** **The order has some claims with delivered but wrong reason** */
  DeliveredButWrong = 'DELIVERED_BUT_WRONG',
  /** **The order has no claim** */
  None = 'NONE',
  /** **The order has some claims with not delivered and returned to sender reason** */
  NotDeliveredAndReturnedToSender = 'NOT_DELIVERED_AND_RETURNED_TO_SENDER',
  /** **The order has some claims with not delivered and tracking blocked reason** */
  NotDeliveredAndTrackingBlocked = 'NOT_DELIVERED_AND_TRACKING_BLOCKED',
  /** **The order has some claims with not delivered and tracking delivered reason** */
  NotDeliveredAndTrackingDelivered = 'NOT_DELIVERED_AND_TRACKING_DELIVERED',
  /** **The order has some claims with other reason** */
  Other = 'OTHER',
  /** **The order has some claims with voucher not working reason** */
  VoucherNotWorking = 'VOUCHER_NOT_WORKING',
}

/** **Order tag fulfillment** */
export enum OrderTagFulfillment {
  /** **The order has all the items that are eligible to shipment in a shipment** */
  Fulfilled = 'FULFILLED',
  /** **The order has none of the items that are eligible to shipment in a shipment** */
  Unfulfilled = 'UNFULFILLED',
}

/** **Order tag refund** */
export enum OrderTagRefund {
  /** **The order has no refund** */
  None = 'NONE',
  /** **The order has some refunds but not totally refunded** */
  PartiallyRefunded = 'PARTIALLY_REFUNDED',
  /** **The order has some refunds and totally refunded** */
  TotallyRefunded = 'TOTALLY_REFUNDED',
}

/** **Order tag reship** */
export enum OrderTagReship {
  /** **The order has no reship** */
  None = 'NONE',
  /** **The order has some reships** */
  Reshipped = 'RESHIPPED',
}

/** **Order tag return** */
export enum OrderTagReturn {
  /** **The order has some canceled returns** */
  Canceled = 'CANCELED',
  /** **The order has some delivered returns** */
  Delivered = 'DELIVERED',
  /** **The order has some in progress returns** */
  InProgress = 'IN_PROGRESS',
  /** **The order has no return** */
  None = 'NONE',
  /** **The order has some requested returns** */
  Requested = 'REQUESTED',
  /** **The order has some validated returns** */
  Validated = 'VALIDATED',
}

/** **Order tag shipment** */
export enum OrderTagShipment {
  /** **The order has some attempt fail shipments** */
  AttemptFail = 'ATTEMPT_FAIL',
  /** **The order has some available for pickup shipments** */
  AvailableForPickup = 'AVAILABLE_FOR_PICKUP',
  /** **The order has some delivered shipments** */
  Delivered = 'DELIVERED',
  /** **The order has some exception shipments** */
  Exception = 'EXCEPTION',
  /** **The order has some expired shipments** */
  Expired = 'EXPIRED',
  /** **The order has some info received shipments** */
  InfoReceived = 'INFO_RECEIVED',
  /** **The order has some in transit shipments** */
  InTransit = 'IN_TRANSIT',
  /** **The order has no shipment** */
  None = 'NONE',
  /** **The order has some out for delivery shipments** */
  OutForDelivery = 'OUT_FOR_DELIVERY',
  /** **The order has some pending shipments** */
  Pending = 'PENDING',
}

/** **Order tag shipping mode** */
export enum OrderTagShippingMode {
  /** **The shipments will be shipped at the recipient's home** */
  Home = 'HOME',
  /** **The order will not be shipped (digital items)** */
  None = 'NONE',
  /** **The shipments will be shipped at a pickup point** */
  PickupPoint = 'PICKUP_POINT',
}

/** **Order tag shopify** */
export enum OrderTagShopify {
  /** **The order has no shopify** */
  None = 'NONE',
  /** **The order has a shopify and is synchronized** */
  Synchronized = 'SYNCHRONIZED',
  /** **The order has a shopify and is not synchronized** */
  Unsynchronized = 'UNSYNCHRONIZED',
}

/** **Order tag status** */
export enum OrderTagStatus {
  /** **The order has been canceled by the customer or by a member of the support team** */
  Canceled = 'CANCELED',
  /** **The order has been placed less than 15 minutes ago** */
  PendingValidation = 'PENDING_VALIDATION',
  /** **The order has been placed more than 15 minutes ago** */
  Validated = 'VALIDATED',
}

/** **Order tag type** */
export enum OrderTagType {
  /** **The order has only digital items** */
  Digital = 'DIGITAL',
  /** **The order has only physical items** */
  Physical = 'PHYSICAL',
}

/** **Order tags** */
export type OrderTags = {
  __typename?: 'OrderTags';
  /** **Brand tags** */
  brand: Array<OrderTagBrand>;
  /** **Claim tags** */
  claim: Array<OrderTagClaim>;
  /** **Claim reason tags** */
  claimReason: Array<OrderTagClaimReason>;
  /** **Fulfillment tag** */
  fulfillment: OrderTagFulfillment;
  /** **Refund tag** */
  refund: OrderTagRefund;
  /** **Reship tag** */
  reship: OrderTagReship;
  /** **Return tags** */
  return: Array<OrderTagReturn>;
  /** **Shipment tags** */
  shipment: Array<OrderTagShipment>;
  /** **Shipping mode tag** */
  shippingMode: OrderTagShippingMode;
  /** **Shopify tag** */
  shopify: OrderTagShopify;
  /** **Status tag** */
  status: OrderTagStatus;
  /** **Type tag** */
  type: OrderTagType;
};

/** **Order updated** */
export type OrderUpdated = {
  __typename?: 'OrderUpdated';
  id: Scalars['ID']['output'];
};

/** **Order V2 (Order is already taken)** */
export type OrderV2 = {
  __typename?: 'OrderV2';
  /** **Billing ID** */
  billingId: Scalars['ID']['output'];
  /** **The buyer is the person who has been billed for the order** */
  buyer: Buyer;
  /** **Claims** */
  claims: Array<Claim>;
  /** **Date of the creation** */
  createdAt: Scalars['DateTime']['output'];
  /** **Customer ID** */
  customerId: Scalars['ID']['output'];
  /** **Has been exported by the seller ?  (using a CSV from the dashboard in order to not process it twice)** */
  hasBeenExported: Scalars['Boolean']['output'];
  /** **Has seller error ?** */
  hasSellerError: Scalars['Boolean']['output'];
  /** **Has synchronization error ?** */
  hasSynchronizationError: Scalars['Boolean']['output'];
  /** **ID** */
  id: Scalars['ID']['output'];
  /** **Initial order if this is a reship order** */
  initialOrder?: Maybe<OrderV2>;
  /** **ID of the initial order if this is a reship order** */
  initialOrderId?: Maybe<Scalars['ID']['output']>;
  /** **Integration** */
  integration: OrderIntegration;
  /** **Is cancelled ?** */
  isCancelled: Scalars['Boolean']['output'];
  /** **Does the order include only digital items ?** */
  isFullDigital: Scalars['Boolean']['output'];
  /** **Is shipping estimated time exceeded ?** */
  isShippingEstimatedTimeExceeded: Scalars['Boolean']['output'];
  /** **Items ordered by the customer** */
  items: Array<Item>;
  /** **Lines refunds** */
  linesRefunds: Array<OrderLineRefund>;
  /** **Operation type** */
  operationType: OperationType;
  /** **Parcels** */
  parcels: Array<Parcel>;
  /** **Payment infos** */
  payment: OrderPayment;
  /** **Rating** */
  rating?: Maybe<OrderRating>;
  /** **The recipient is the person who will receive the order** */
  recipient: Recipient;
  /** **Reship order if this is an initial order reshipped** */
  reshipOrder?: Maybe<OrderV2>;
  /** **ID of the reship order if this is an initial order reshipped** */
  reshipOrderId?: Maybe<Scalars['ID']['output']>;
  /** **Return parcels** */
  returnParcels: Array<ReturnParcel>;
  /** **Return slips** */
  returnSlips: Array<ReturnSlip>;
  /** **Sale ID** */
  saleId: Scalars['ID']['output'];
  /** **Seller ID** */
  sellerId: Scalars['ID']['output'];
  /** **Shipping infos** */
  shipping: OrderShipping;
  /** **Split action error reasons if the action is not available** */
  splitActionErrorReasons: Array<OrderSplitActionErrorReason>;
  /** **Tags** */
  tags: OrderTags;
  /** **Total price sold (items) by Choose to the customer (in cents)** */
  totalPriceSold: Price;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
};

/** **Paging** */
export type Paging = {
  __typename?: 'Paging';
  /** **End cursor** */
  endCursor?: Maybe<Scalars['ID']['output']>;
  /** **Has next page ?** */
  hasNextPage: Scalars['Boolean']['output'];
  /** **Has previous page ?** */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** **Page size** */
  pageSize: Scalars['PositiveInteger']['output'];
  /** **Start cursor** */
  startCursor?: Maybe<Scalars['ID']['output']>;
};

/** **Paging input** */
export type PagingInput = {
  /** **After cursor** */
  afterCursor?: InputMaybe<Scalars['ID']['input']>;
  /** **Before cursor** */
  beforeCursor?: InputMaybe<Scalars['ID']['input']>;
  /** **Page size** */
  pageSize: Scalars['PositiveInteger']['input'];
};

/** **Parcel** */
export type Parcel = {
  __typename?: 'Parcel';
  /**
   * **Does the parcel has its tracking stucked or not ?**
   *
   * - only if more than 3 days in 'pending' for now
   */
  hasTrackingStucked: Scalars['Boolean']['output'];
  /** **ID** */
  id: Scalars['ID']['output'];
  /** **Items** */
  items: Array<Item>;
  /** **Recipient** */
  recipient: Recipient;
  /** **Tracking carrier slug** */
  trackingCarrierSlug: Scalars['String']['output'];
  /** **Date when the parcel was delivered** */
  trackingDeliveredAt?: Maybe<Scalars['DateTime']['output']>;
  /** **Tracking number** */
  trackingNumber: Scalars['String']['output'];
  /** **Tracking status** */
  trackingStatus: TrackingStatus;
  /** **Tracking sub status** */
  trackingSubStatus?: Maybe<TrackingSubStatus>;
  /** **Date of the last tracking update** */
  trackingUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** **Tracking URL** */
  trackingUrl: Scalars['String']['output'];
};

/** **Payment processor** */
export enum PaymentProcessor {
  /** **Alma** */
  Alma = 'Alma',
  /** **Braintree** */
  Braintree = 'Braintree',
  /** **Choose** */
  Choose = 'Choose',
  /** **Primer** */
  Primer = 'Primer',
  /** **Stripe** */
  Stripe = 'Stripe',
}

/** **Payment Sub-processor - Stripe Specific ** */
export enum PaymentSubProcessor {
  /** **Bancontact** */
  Bancontact = 'Bancontact',
  /** **Klarna** */
  Klarna = 'Klarna',
  /** **PayPal** */
  PayPal = 'PayPal',
}

/** **Pickup point address** */
export type PickupAddress = {
  __typename?: 'PickupAddress';
  /** **city** */
  city: Scalars['String']['output'];
  /** **Country code** */
  countryCode: Scalars['String']['output'];
  /** **name** */
  name: Scalars['String']['output'];
  /** **street** */
  street: Scalars['String']['output'];
  /** **streetAdditional** */
  streetAdditional?: Maybe<Scalars['String']['output']>;
  /** **zipCode** */
  zipCode: Scalars['String']['output'];
};

export type PickupOpeningDays = {
  __typename?: 'PickupOpeningDays';
  friday?: Maybe<Array<PickupOpeningHours>>;
  monday?: Maybe<Array<PickupOpeningHours>>;
  saturday?: Maybe<Array<PickupOpeningHours>>;
  sunday?: Maybe<Array<PickupOpeningHours>>;
  thursday?: Maybe<Array<PickupOpeningHours>>;
  tuesday?: Maybe<Array<PickupOpeningHours>>;
  wednesday?: Maybe<Array<PickupOpeningHours>>;
};

export type PickupOpeningHours = {
  __typename?: 'PickupOpeningHours';
  close: Scalars['String']['output'];
  open: Scalars['String']['output'];
};

/** **Pickup point** */
export type PickupPoint = {
  __typename?: 'PickupPoint';
  /** **Address** */
  address?: Maybe<PickupAddress>;
  /** **Country code** */
  countryCode: Scalars['String']['output'];
  /** **Id** */
  id: Scalars['ID']['output'];
  /** **Network** */
  network: PickupPointNetwork;
  /** **PickupOpeningDays** */
  openingDays: PickupOpeningDays;
};

/** **Pickup point network** */
export enum PickupPointNetwork {
  /** **Colissimo** */
  Colissimo = 'COLISSIMO',
  /** **Mondial relay** */
  MondialRelay = 'MONDIAL_RELAY',
  /** **Shop2Shop** */
  Shop2Shop = 'SHOP2SHOP',
}

export type Plugins = {
  __typename?: 'Plugins';
  shopify?: Maybe<Scalars['Json']['output']>;
};

/** **Price** */
export type Price = {
  __typename?: 'Price';
  /** **Currency ISO code** */
  currency: CurrencyIso;
  /** **Value with VAT (in cents)** */
  valueWithVat: Scalars['Int']['output'];
  /** **Value without VAT (in cents)** */
  valueWithoutVat: Scalars['Int']['output'];
};

export type Product = {
  __typename?: 'Product';
  category?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  images?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Array<Maybe<Option>>>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  variants?: Maybe<Array<Maybe<Variant>>>;
};

export type ProductSoldError = {
  __typename?: 'ProductSoldError';
  code: Scalars['String']['output'];
  id: Scalars['String']['output'];
  order_id: Scalars['String']['output'];
  product_id: Scalars['String']['output'];
};

/** **Product stock updated** */
export type ProductStockUpdated = {
  __typename?: 'ProductStockUpdated';
  productId: Scalars['ID']['output'];
  productVariantId: Scalars['ID']['output'];
  stock: Scalars['Int']['output'];
};

export enum ProductsSort {
  Name = 'NAME',
  Stock = 'STOCK',
}

export type Profile = {
  __typename?: 'Profile';
  email: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  lastname: Scalars['String']['output'];
  picture: Scalars['String']['output'];
  role: Scalars['String']['output'];
  user_id: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  /** **Get information about state of billing** */
  billingState: BillingState;
  billingV2: Billing;
  brand?: Maybe<Brand>;
  getBrandUsers: Array<Maybe<Profile>>;
  getUploadUrl?: Maybe<Scalars['Json']['output']>;
  products?: Maybe<Array<Maybe<Product>>>;
  ratings?: Maybe<Rates>;
  returns: Returns;
  /** **Get statistics for a sale per days ** */
  statsHistogram: Array<HistogramElement>;
  /** **Get statistics for a sale** */
  statsV2: StatsV2;
  topProductsStats: TopProductsStats;
  /** **Verify shopify identification ** */
  verifyShopifyCredentials: VerifyShopifyCredentialsOutput;
};

export type QueryBillingStateArgs = {
  saleId: Scalars['String']['input'];
};

export type QueryBillingV2Args = {
  saleId: Scalars['String']['input'];
};

export type QueryGetUploadUrlArgs = {
  upload: Scalars['Upload']['input'];
};

export type QueryProductsArgs = {
  saleId: Scalars['String']['input'];
  sort?: InputMaybe<ProductsSort>;
};

export type QueryRatingsArgs = {
  filter?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  onlyWithMessage?: InputMaybe<Scalars['Boolean']['input']>;
  saleId: Scalars['String']['input'];
};

export type QueryReturnsArgs = {
  saleId: Scalars['String']['input'];
};

export type QueryStatsHistogramArgs = {
  saleId: Scalars['NonEmptyString']['input'];
};

export type QueryStatsV2Args = {
  saleId: Scalars['NonEmptyString']['input'];
};

export type QueryTopProductsStatsArgs = {
  optionKey?: InputMaybe<Scalars['String']['input']>;
  saleId: Scalars['String']['input'];
};

export type QueryVerifyShopifyCredentialsArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

/** **Count of rates given by rates values** */
export type RateCountAggByRate = {
  __typename?: 'RateCountAggByRate';
  /** **Count of rates given for this rate value** */
  count: Scalars['Int']['output'];
  /** **Rate value** */
  rate: Scalars['Int']['output'];
};

export type Rates = {
  __typename?: 'Rates';
  nodes: Array<Rating>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type Rating = {
  __typename?: 'Rating';
  avatar?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order_id?: Maybe<Scalars['String']['output']>;
  products?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  rate?: Maybe<Scalars['Int']['output']>;
  rate_message?: Maybe<Scalars['String']['output']>;
  rate_updated_at?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** **The recipient is the person who will receive the order** */
export type Recipient = {
  __typename?: 'Recipient';
  /** **Address** */
  address: AddressV2;
  /** **Email** */
  email: Scalars['String']['output'];
  /** **First name** */
  firstName: Scalars['String']['output'];
  /** **Full name ("first_name last_name")** */
  fullName: Scalars['String']['output'];
  /** **Identifier** */
  id: Scalars['ID']['output'];
  /** **Last name** */
  lastName: Scalars['String']['output'];
  /** **Phone number** */
  phoneNumber: Scalars['String']['output'];
  /** **Previous recipient if it has been updated** */
  previous?: Maybe<Recipient>;
};

/** **ReturnParcel** */
export type ReturnParcel = {
  __typename?: 'ReturnParcel';
  /** **ID** */
  id: Scalars['ID']['output'];
  /** **Items** */
  items: Array<Item>;
  /** **Return slip id** */
  returnSlipId: Scalars['ID']['output'];
  /** **Tracking carrier slug** */
  trackingCarrierSlug: Scalars['String']['output'];
  /** **Date when the parcel was delivered** */
  trackingDeliveredAt?: Maybe<Scalars['DateTime']['output']>;
  /** **Tracking number** */
  trackingNumber: Scalars['String']['output'];
  /** **Tracking status** */
  trackingStatus: TrackingStatus;
  /** **Date of the last tracking update** */
  trackingUpdatedAt: Scalars['DateTime']['output'];
  /** **Tracking URL** */
  trackingUrl: Scalars['String']['output'];
};

export type ReturnProduct = {
  __typename?: 'ReturnProduct';
  description: Scalars['String']['output'];
  image: Scalars['String']['output'];
  options?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  sum: Scalars['Int']['output'];
};

/** **Return slip** */
export type ReturnSlip = {
  __typename?: 'ReturnSlip';
  /** **Created at** */
  createdAt: Scalars['DateTime']['output'];
  /** **ID** */
  id: Scalars['ID']['output'];
  /** **Items** */
  items: Array<Item>;
  /** **Tracking carrier slug** */
  trackingCarrierSlug: Scalars['String']['output'];
  /** **Tracking number** */
  trackingNumber: Scalars['String']['output'];
  /** **Tracking URL** */
  trackingUrl: Scalars['String']['output'];
};

export type ReturnStat = {
  __typename?: 'ReturnStat';
  code: Scalars['String']['output'];
  sum: Scalars['Int']['output'];
};

export type Returns = {
  __typename?: 'Returns';
  percentage?: Maybe<Scalars['Float']['output']>;
  products?: Maybe<Array<Maybe<ReturnProduct>>>;
  returns?: Maybe<Array<Maybe<ReturnStat>>>;
  total_orders?: Maybe<Scalars['Int']['output']>;
  total_sum?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum Role {
  Admin = 'ADMIN',
  Api = 'API',
  Brand = 'BRAND',
  Unknown = 'UNKNOWN',
}

export type Sale = {
  __typename?: 'Sale';
  brand_id: Scalars['String']['output'];
  category?: Maybe<Scalars['String']['output']>;
  currency: StoreCurrency;
  description?: Maybe<Scalars['String']['output']>;
  end_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  is_selection?: Maybe<Scalars['Boolean']['output']>;
  logistics_manager?: Maybe<Scalars['String']['output']>;
  selection_name?: Maybe<Scalars['String']['output']>;
  start_at?: Maybe<Scalars['DateTime']['output']>;
  store: StoreRegion;
  widgets?: Maybe<Array<Maybe<Widget>>>;
};

/** **Sale status** */
export enum SaleStatus {
  /** **Sale is ended** */
  Ended = 'ENDED',
  /** **Sale is ongoing** */
  Ongoing = 'ONGOING',
  /** **Sale is upcoming** */
  Upcoming = 'UPCOMING',
}

export type ShopifyCredentialsInput = {
  carrierCode?: InputMaybe<Scalars['String']['input']>;
  internationalCarrierCode?: InputMaybe<Scalars['String']['input']>;
  /** **Set the price of an order while sending data to shopify (either 0 or the real price)** */
  isProductPriceKept: Scalars['Boolean']['input'];
  isStockLive: Scalars['Boolean']['input'];
  mondialRelayCarrierCode?: InputMaybe<Scalars['String']['input']>;
  sharedSecret: Scalars['String']['input'];
  shop: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type ShopifyData = {
  __typename?: 'ShopifyData';
  carrierCode?: Maybe<Scalars['String']['output']>;
  internationalCarrierCode?: Maybe<Scalars['String']['output']>;
  isProductPriceKept: Scalars['Boolean']['output'];
  isStockLive: Scalars['Boolean']['output'];
  mondialRelayCarrierCode?: Maybe<Scalars['String']['output']>;
  sharedSecret?: Maybe<Scalars['String']['output']>;
  shop: Scalars['String']['output'];
  token?: Maybe<Scalars['String']['output']>;
};

/** **Shopify order integration** */
export type ShopifyOrderIntegration = {
  __typename?: 'ShopifyOrderIntegration';
  /** **ID of the order from Shopify** */
  orderId: Scalars['ID']['output'];
  /** **URL of the order from Shopify** */
  orderUrl: Scalars['String']['output'];
};

/** **Statistics for a sale** */
export type StatsV2 = {
  __typename?: 'StatsV2';
  /** **Brand is subject to VAT ?** */
  isSubjectToVat: Scalars['Boolean']['output'];
  /**
   * **Count of orders**
   *
   * - > not removed
   * - > not from another order
   * - > older than 15min ago
   */
  orderCount: Scalars['Int']['output'];
  /**
   * **Count of products sold**
   *
   * - > from orders not removed
   * - > from orders not from another order
   * - > from orders older than 15min ago
   */
  productSoldCount: Scalars['Int']['output'];
  /**
   * **Average of rates given** (can be `null` if there is no rate)
   *
   * - > from orders not removed
   * - > from orders not from another order
   * - > from orders older than 15min ago
   */
  rateAvg?: Maybe<Scalars['Float']['output']>;
  /**
   * **Count of rates given**
   *
   * - > from orders not removed
   * - > from orders not from another order
   * - > from orders older than 15min ago
   */
  rateCount: Scalars['Int']['output'];
  /**
   * **Count of rates given by rates values**
   *
   * - > from orders not removed
   * - > from orders not from another order
   * - > from orders older than 15min ago
   */
  rateCountAggByRates: Array<RateCountAggByRate>;
  /**
   * **Amount due to the brand (without VAT)**
   *
   * - > *including* : shipping fees
   * - > *excluding* : commissions, refunds, returns
   */
  totalBillingHt: Scalars['Int']['output'];
  /**
   * **Amount due to the brand (include VAT)**
   *
   * - > *including* : shipping fees
   * - > *excluding* : commissions, refunds, returns
   */
  totalBillingTtc: Scalars['Int']['output'];
  /**
   * **Amount turnover (without VAT)**
   *
   * - > *including* : shipping fees, commissions
   * - > *excluding* : refunds, returns
   */
  totalTurnoverHt: Scalars['Int']['output'];
  /**
   * **Amount turnover (include VAT)**
   *
   * - > *including* : shipping fees, commissions
   * - > *excluding* : refunds, returns
   */
  totalTurnoverTtc: Scalars['Int']['output'];
};

/** **Stock - TODO: To remove** */
export type Stock = {
  __typename?: 'Stock';
  brand_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  product_id?: Maybe<Scalars['String']['output']>;
  stock?: Maybe<Scalars['Int']['output']>;
  variant_id?: Maybe<Scalars['String']['output']>;
};

export enum StoreCurrency {
  Eur = 'eur',
  Usd = 'usd',
}

export enum StoreRegion {
  Fr = 'fr',
  Us = 'us',
}

/** **Subscription** */
export type Subscription = {
  __typename?: 'Subscription';
  /** **Invoice created** */
  invoiceCreated: InvoiceCreated;
  /** **Invoice updated** */
  invoiceUpdated: InvoiceUpdated;
  /** **Order created** */
  orderCreated: OrderCreated;
  /** **Order updated** */
  orderUpdated: OrderUpdated;
  /** **Product stock updated** */
  productStockUpdated: ProductStockUpdated;
  /** **Widget updated** */
  widgetUpdated?: Maybe<Widget>;
};

export type TopProductInfo = {
  __typename?: 'TopProductInfo';
  group_id: Scalars['String']['output'];
  image_url: Scalars['String']['output'];
  options: Array<Scalars['String']['output']>;
  product_id: Scalars['String']['output'];
  product_name: Scalars['String']['output'];
  purchased_count: Scalars['Int']['output'];
  total_amount: Scalars['Int']['output'];
  variants: Array<TopProductVariantInfo>;
};

export type TopProductVariantInfo = {
  __typename?: 'TopProductVariantInfo';
  image_url: Scalars['String']['output'];
  option_value?: Maybe<Scalars['String']['output']>;
  options: Array<Scalars['String']['output']>;
  price: Scalars['Int']['output'];
  product_id: Scalars['String']['output'];
  product_name: Scalars['String']['output'];
  purchased_count: Scalars['Int']['output'];
  total_amount: Scalars['Int']['output'];
  variant_id: Scalars['String']['output'];
};

export type TopProductsStats = {
  __typename?: 'TopProductsStats';
  available_option_keys: Array<Scalars['String']['output']>;
  current_option_key?: Maybe<Scalars['String']['output']>;
  products: Array<Maybe<TopProductInfo>>;
};

/** **Tracking status** */
export enum TrackingStatus {
  /** **Attempt fail** */
  AttemptFail = 'ATTEMPT_FAIL',
  /** **Available for pickup** */
  AvailableForPickup = 'AVAILABLE_FOR_PICKUP',
  /** **Delivered** */
  Delivered = 'DELIVERED',
  /** **Exception** */
  Exception = 'EXCEPTION',
  /** **Expired** */
  Expired = 'EXPIRED',
  /** **Info received** */
  InfoReceived = 'INFO_RECEIVED',
  /** **In transit** */
  InTransit = 'IN_TRANSIT',
  /** **Out for delivery** */
  OutForDelivery = 'OUT_FOR_DELIVERY',
  /** **Pending** */
  Pending = 'PENDING',
}

/** **Tracking sub status** */
export enum TrackingSubStatus {
  /** **Business is closed at the time of delivery (AttemptFail_003)** */
  AttemptFailBusinessClosed = 'ATTEMPT_FAIL_BUSINESS_CLOSED',
  /** **The delivery of the package failed due to some reason. Courier usually leaves a notice and will try to deliver again (AttemptFail_001)** */
  AttemptFailDueToSomeReason = 'ATTEMPT_FAIL_DUE_TO_SOME_REASON',
  /** **Recipient not available at the given address (AttemptFail_002)** */
  AttemptFailRecipientNotAvailable = 'ATTEMPT_FAIL_RECIPIENT_NOT_AVAILABLE',
  /** **The package arrived at a pickup point near you and is available for pickup (AvailableForPickup_001)** */
  AvailableForPickup = 'AVAILABLE_FOR_PICKUP',
  /** **Package delivered to the recipient and cash collected on delivery (Delivered_004)** */
  DeliveredAndCashCollected = 'DELIVERED_AND_CASH_COLLECTED',
  /** **Package delivered to and signed by the recipient (Delivered_003)** */
  DeliveredAndSignedByRecipient = 'DELIVERED_AND_SIGNED_BY_RECIPIENT',
  /** **CUSTOM: Marked delivered by a bot (Delivered_Mark_By_Bot)** */
  DeliveredMarkedByBot = 'DELIVERED_MARKED_BY_BOT',
  /** **CUSTOM: Marked delivered by the customer (Delivered_Mark_By_Customer)** */
  DeliveredMarkedByCustomer = 'DELIVERED_MARKED_BY_CUSTOMER',
  /** **CUSTOM: Marked delivered by a member of the support team (Delivered_Mark_By_Support)** */
  DeliveredMarkedBySupportTeam = 'DELIVERED_MARKED_BY_SUPPORT_TEAM',
  /** **Package picked up by the recipient (Delivered_002)** */
  DeliveredPickedUpByRecipient = 'DELIVERED_PICKED_UP_BY_RECIPIENT',
  /** **Shipment delivered successfully (Delivered_001)** */
  DeliveredSuccessfully = 'DELIVERED_SUCCESSFULLY',
  /** **The package is on its way back to the sender (Exception_010)** */
  ExceptionBackToSender = 'EXCEPTION_BACK_TO_SENDER',
  /** **The return package has been successfully received by the sender (Exception_011)** */
  ExceptionBackToSenderReceived = 'EXCEPTION_BACK_TO_SENDER_RECEIVED',
  /** **Shipment damaged (Exception_012)** */
  ExceptionDamaged = 'EXCEPTION_DAMAGED',
  /** **Package delayed due to some issues during the customs clearance (Exception_004)** */
  ExceptionDelayedCustomsClearance = 'EXCEPTION_DELAYED_CUSTOMS_CLEARANCE',
  /** **Package not delivered due to incorrect recipient address (Exception_007)** */
  ExceptionIncorrectRecipientAddress = 'EXCEPTION_INCORRECT_RECIPIENT_ADDRESS',
  /** **Delivery of the package failed as it got lost (Exception_013)** */
  ExceptionLost = 'EXCEPTION_LOST',
  /** **The package being held due to pending payment from the recipient's end (Exception_006)** */
  ExceptionPendingPayment = 'EXCEPTION_PENDING_PAYMENT',
  /** **Package available for the pickup but not collected by the recipient (Exception_008)** */
  ExceptionPickupNotCollectedByRecipient = 'EXCEPTION_PICKUP_NOT_COLLECTED_BY_RECIPIENT',
  /** **Delivery of the package failed as the recipient refused to take the package due to some reason (Exception_003)** */
  ExceptionRecipientRefused = 'EXCEPTION_RECIPIENT_REFUSED',
  /** **Delivery of the package failed as the recipient relocated (Exception_002)** */
  ExceptionRecipientRelocated = 'EXCEPTION_RECIPIENT_RELOCATED',
  /** **Package rejected by the carrier due to noncompliance with its guidelines (Exception_009)** */
  ExceptionRejectedByCarrier = 'EXCEPTION_REJECTED_BY_CARRIER',
  /** **Delivery of the package failed due to some shipping exception (Exception_001)** */
  ExceptionShippingException = 'EXCEPTION_SHIPPING_EXCEPTION',
  /** **Package delayed due to some unforeseen reasons (Exception_005)** */
  ExceptionUnforseenReason = 'EXCEPTION_UNFORSEEN_REASON',
  /** **No tracking information of the shipment, from the last 30 days (Expired_001)** */
  ExpiredNoTrackingInformation = 'EXPIRED_NO_TRACKING_INFORMATION',
  /** **The carrier received a request from the shipper and is about to pick up the shipment (InfoReceived_001)** */
  InfoReceivedAboutToPickup = 'INFO_RECEIVED_ABOUT_TO_PICKUP',
  /** **Shipment accepted by the carrier (InTransit_002)** */
  InTransitAcceptedByCarrier = 'IN_TRANSIT_ACCEPTED_BY_CARRIER',
  /** **Shipment arrived at a hub or sorting center (InTransit_003)** */
  InTransitArrivedAtAHubOrSortingCenter = 'IN_TRANSIT_ARRIVED_AT_A_HUB_OR_SORTING_CENTER',
  /** **International shipment arrived at the destination country / region (InTransit_004)** */
  InTransitArrivedAtDestinationCountryOrRegion = 'IN_TRANSIT_ARRIVED_AT_DESTINATION_COUNTRY_OR_REGION',
  /** **Customs clearance completed (InTransit_005)** */
  InTransitCustomsClearanceCompleted = 'IN_TRANSIT_CUSTOMS_CLEARANCE_COMPLETED',
  /** **Package departed from the facility (InTransit_007)** */
  InTransitDepartedFromFacility = 'IN_TRANSIT_DEPARTED_FROM_FACILITY',
  /** **Shipment forwarded to a different delivery address (InTransit_009)** */
  InTransitForwardedToADifferentDeliveryAddress = 'IN_TRANSIT_FORWARDED_TO_A_DIFFERENT_DELIVERY_ADDRESS',
  /** **Package handed over to customs for clearance (InTransit_006)** */
  InTransitHandedOverToCustomsForClearance = 'IN_TRANSIT_HANDED_OVER_TO_CUSTOMS_FOR_CLEARANCE',
  /** **Shipment on the way (InTransit_001)** */
  InTransitOnTheWay = 'IN_TRANSIT_ON_THE_WAY',
  /** **Problem resolved and shipment in transit (InTransit_008)** */
  InTransitProblemResolved = 'IN_TRANSIT_PROBLEM_RESOLVED',
  /** **The package is out for delivery (OutForDelivery_001)** */
  OutForDelivery = 'OUT_FOR_DELIVERY',
  /** **A delivery appointment is scheduled (OutForDelivery_004)** */
  OutForDeliveryAppointmentScheduled = 'OUT_FOR_DELIVERY_APPOINTMENT_SCHEDULED',
  /** **The recipient is contacted before the final delivery (OutForDelivery_003)** */
  OutForDeliveryRecipientContacted = 'OUT_FOR_DELIVERY_RECIPIENT_CONTACTED',
  /** **Can’t track this type of shipment as the carrier is unrecognized (Pending_006)** */
  PendingCarrierUnrecognized = 'PENDING_CARRIER_UNRECOGNIZED',
  /** **The order has been processed / packaged, but not scanned at a shipping location yet (Pending_003)** */
  PendingNotScanned = 'PENDING_NOT_SCANNED',
  /** **It represents the shipments are pending due to no connection with carrier accounts (Pending_002)** */
  PendingNoConnectionWithCarrier = 'PENDING_NO_CONNECTION_WITH_CARRIER',
  /** **No information available on the carrier website or the tracking number is yet to be tracked (Pending_001)** */
  PendingNoInformationAvailable = 'PENDING_NO_INFORMATION_AVAILABLE',
  /** **There have been no new tracking updates in the last 120 days (Pending_005)** */
  PendingNoUpdate = 'PENDING_NO_UPDATE',
  /** **There is no tracking info available because the carrier is wrong (Pending_004)** */
  PendingWrongCarrier = 'PENDING_WRONG_CARRIER',
}

export type TranslateResponse = {
  __typename?: 'TranslateResponse';
  originalLanguage?: Maybe<Scalars['String']['output']>;
  translation: Scalars['String']['output'];
};

export type UpdateSeller = {
  accept_contract?: InputMaybe<Scalars['Boolean']['input']>;
  account_number?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['Json']['input']>;
  bank_address?: InputMaybe<Scalars['Json']['input']>;
  beneficiary_name?: InputMaybe<Scalars['String']['input']>;
  contact_email?: InputMaybe<Scalars['String']['input']>;
  contact_phone?: InputMaybe<Scalars['String']['input']>;
  delivery_services?: InputMaybe<Scalars['Json']['input']>;
  has_shopify?: InputMaybe<Scalars['Boolean']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  intracommunity_vat?: InputMaybe<Scalars['String']['input']>;
  is_autoentrepreneur?: InputMaybe<Scalars['Boolean']['input']>;
  is_french?: InputMaybe<Scalars['Boolean']['input']>;
  max_delivery_delay?: InputMaybe<Scalars['Int']['input']>;
  min_delivery_delay?: InputMaybe<Scalars['Int']['input']>;
  return_address?: InputMaybe<Scalars['Json']['input']>;
  routing_code?: InputMaybe<Scalars['String']['input']>;
  shipping_country?: InputMaybe<Scalars['String']['input']>;
  shipping_services?: InputMaybe<Scalars['Json']['input']>;
  shopify_url?: InputMaybe<Scalars['String']['input']>;
  siret?: InputMaybe<Scalars['String']['input']>;
  swift_code?: InputMaybe<Scalars['String']['input']>;
  tax_id?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateShopifyInfoInput = {
  carrierCode?: InputMaybe<Scalars['String']['input']>;
  internationalCarrierCode?: InputMaybe<Scalars['String']['input']>;
  /** **Set the price of an order while sending data to shopify (either 0 or the real price)** */
  isProductPriceKept: Scalars['Boolean']['input'];
  isStockLive: Scalars['Boolean']['input'];
  mondialRelayCarrierCode?: InputMaybe<Scalars['String']['input']>;
  sharedSecret?: InputMaybe<Scalars['String']['input']>;
  /** ** Connection information */
  shop?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWidget = {
  results?: InputMaybe<Scalars['Json']['input']>;
  state: WidgetState;
};

export type UpdatedShopifyData = {
  __typename?: 'UpdatedShopifyData';
  carrierCode?: Maybe<Scalars['String']['output']>;
  internationalCarrierCode?: Maybe<Scalars['String']['output']>;
  isProductPriceKept: Scalars['Boolean']['output'];
  isStockLive: Scalars['Boolean']['output'];
  mondialRelayCarrierCode?: Maybe<Scalars['String']['output']>;
};

export type Variant = {
  __typename?: 'Variant';
  buying_price?: Maybe<Scalars['Int']['output']>;
  delivery_delays?: Maybe<Scalars['Int']['output']>;
  discount?: Maybe<Scalars['Int']['output']>;
  gtin?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  images?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  options?: Maybe<Scalars['Json']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  product_id?: Maybe<Scalars['String']['output']>;
  retail_price?: Maybe<Scalars['Int']['output']>;
  shipping_cost?: Maybe<Scalars['Int']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  stock?: Maybe<Scalars['Int']['output']>;
  stock_id: Scalars['String']['output'];
};

export type VerifyShopifyCredentialsOutput = {
  __typename?: 'VerifyShopifyCredentialsOutput';
  /** **Tells if the access token is correct for url provided** */
  isValidAccessToken: Scalars['Boolean']['output'];
  /** **Tells if the URL is a valid shopify url** */
  isValidUrl: Scalars['Boolean']['output'];
};

export type Widget = {
  __typename?: 'Widget';
  brand_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  proposition?: Maybe<Scalars['Json']['output']>;
  results?: Maybe<Scalars['Json']['output']>;
  state?: Maybe<WidgetState>;
  type?: Maybe<WidgetType>;
};

export enum WidgetState {
  Accepted = 'ACCEPTED',
  Refused = 'REFUSED',
}

export enum WidgetType {
  Duration = 'DURATION',
  Stock = 'STOCK',
}

export type WidgetFieldsFragment = {
  __typename?: 'Widget';
  id: string;
  type?: WidgetType | null;
  state?: WidgetState | null;
  results?: any | null;
  proposition?: any | null;
};

export type BrandQueryVariables = Exact<{ [key: string]: never }>;

export type BrandQuery = {
  __typename?: 'Query';
  brand?: {
    __typename?: 'Brand';
    id: string;
    name?: string | null;
    store: StoreRegion;
    is_french?: boolean | null;
    is_autoentrepreneur?: boolean | null;
    contact_email?: string | null;
    contact_phone?: string | null;
    siret?: string | null;
    intracommunity_vat?: string | null;
    tax_id?: string | null;
    beneficiary_name?: string | null;
    account_number?: string | null;
    routing_code?: string | null;
    swift_code?: string | null;
    bank_address?: any | null;
    iban?: string | null;
    contact_name?: string | null;
    address?: any | null;
    return_address?: any | null;
    shipping_country?: string | null;
    has_shopify?: boolean | null;
    shopify_url?: string | null;
    shipping_services?: any | null;
    max_delivery_delay?: number | null;
    min_delivery_delay?: number | null;
    delivery_services?: any | null;
    accept_contract?: boolean | null;
    need_refresh_info: boolean;
    timezone?: string | null;
    serverTime?: string | null;
    plugins?: { __typename?: 'Plugins'; shopify?: any | null } | null;
    shopify?: {
      __typename?: 'ShopifyData';
      shop: string;
      token?: string | null;
      sharedSecret?: string | null;
      isStockLive: boolean;
      carrierCode?: string | null;
      internationalCarrierCode?: string | null;
      isProductPriceKept: boolean;
      mondialRelayCarrierCode?: string | null;
    } | null;
    sales?: Array<{
      __typename?: 'Sale';
      id: string;
      start_at?: string | null;
      end_at?: string | null;
      logistics_manager?: string | null;
      selection_name?: string | null;
      store: StoreRegion;
      currency: StoreCurrency;
      widgets?: Array<{
        __typename?: 'Widget';
        id: string;
        type?: WidgetType | null;
        state?: WidgetState | null;
        results?: any | null;
        proposition?: any | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type ApiKeyQueryVariables = Exact<{ [key: string]: never }>;

export type ApiKeyQuery = {
  __typename?: 'Query';
  brand?: { __typename?: 'Brand'; api_token?: string | null } | null;
};

export type StockQueryVariables = Exact<{
  saleId: Scalars['String']['input'];
  sort?: InputMaybe<ProductsSort>;
}>;

export type StockQuery = {
  __typename?: 'Query';
  products?: Array<{
    __typename?: 'Product';
    name?: string | null;
    id: string;
    type?: string | null;
    url?: string | null;
    category?: string | null;
    images?: Array<string | null> | null;
    variants?: Array<{
      __typename?: 'Variant';
      images?: Array<string | null> | null;
      sku?: string | null;
      stock?: number | null;
      id: string;
      gtin?: string | null;
      stock_id: string;
      options?: any | null;
    } | null> | null;
  } | null> | null;
};

export type StatsHistogramQueryVariables = Exact<{
  saleId: Scalars['NonEmptyString']['input'];
}>;

export type StatsHistogramQuery = {
  __typename?: 'Query';
  statsHistogram: Array<{
    __typename?: 'HistogramElement';
    nb_distinct_orders?: number | null;
    date: string;
    total_operation_ht?: number | null;
    total_operation_ttc?: number | null;
  }>;
};

export type RatingsQueryVariables = Exact<{
  saleId: Scalars['String']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  onlyWithMessage?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['Int']['input']>;
}>;

export type RatingsQuery = {
  __typename?: 'Query';
  ratings?: {
    __typename?: 'Rates';
    updatedAt?: string | null;
    totalCount?: number | null;
    totalPages?: number | null;
    nodes: Array<{
      __typename?: 'Rating';
      rate?: number | null;
      rate_updated_at?: string | null;
      url?: string | null;
      products?: Array<any | null> | null;
      name?: string | null;
      avatar?: string | null;
      rate_message?: string | null;
      order_id?: string | null;
    }>;
    pageInfo?: { __typename?: 'PageInfo'; hasNextPage?: boolean | null } | null;
  } | null;
};

export type TopProductsStatsQueryVariables = Exact<{
  saleId: Scalars['String']['input'];
  optionKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type TopProductsStatsQuery = {
  __typename?: 'Query';
  topProductsStats: {
    __typename?: 'TopProductsStats';
    available_option_keys: Array<string>;
    current_option_key?: string | null;
    products: Array<{
      __typename?: 'TopProductInfo';
      group_id: string;
      product_id: string;
      product_name: string;
      image_url: string;
      options: Array<string>;
      purchased_count: number;
      total_amount: number;
      variants: Array<{
        __typename?: 'TopProductVariantInfo';
        product_id: string;
        variant_id: string;
        product_name: string;
        image_url: string;
        option_value?: string | null;
        options: Array<string>;
        purchased_count: number;
        price: number;
        total_amount: number;
      }>;
    } | null>;
  };
};

export type StatsQueryVariables = Exact<{
  saleId: Scalars['NonEmptyString']['input'];
}>;

export type StatsQuery = {
  __typename?: 'Query';
  statsV2: {
    __typename?: 'StatsV2';
    rateCount: number;
    rateAvg?: number | null;
    productSoldCount: number;
    orderCount: number;
    totalBillingHt: number;
    totalBillingTtc: number;
    totalTurnoverHt: number;
    totalTurnoverTtc: number;
    isSubjectToVat: boolean;
    rateCountAggByRates: Array<{
      __typename?: 'RateCountAggByRate';
      rate: number;
      count: number;
    }>;
  };
};

export type ReturnsQueryVariables = Exact<{
  saleId: Scalars['String']['input'];
}>;

export type ReturnsQuery = {
  __typename?: 'Query';
  returns: {
    __typename?: 'Returns';
    updatedAt?: string | null;
    total_orders?: number | null;
    total_sum?: number | null;
    percentage?: number | null;
    returns?: Array<{
      __typename?: 'ReturnStat';
      sum: number;
      code: string;
    } | null> | null;
    products?: Array<{
      __typename?: 'ReturnProduct';
      image: string;
      description: string;
      options?: Array<string | null> | null;
      sum: number;
    } | null> | null;
  };
};

export type UploadUrlQueryVariables = Exact<{
  upload: Scalars['Upload']['input'];
}>;

export type UploadUrlQuery = {
  __typename?: 'Query';
  getUploadUrl?: any | null;
};

export type VerifyShopifyCredentialsQueryVariables = Exact<{
  url: Scalars['String']['input'];
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type VerifyShopifyCredentialsQuery = {
  __typename?: 'Query';
  verifyShopifyCredentials: {
    __typename?: 'VerifyShopifyCredentialsOutput';
    isValidAccessToken: boolean;
    isValidUrl: boolean;
  };
};

export type AcceptClaimWithRefundMutationVariables = Exact<{
  orderId: Scalars['ID']['input'];
  claimId: Scalars['ID']['input'];
}>;

export type AcceptClaimWithRefundMutation = {
  __typename?: 'Mutation';
  acceptClaimWithRefund?: any | null;
};

export type AcceptClaimWithReshipMutationVariables = Exact<{
  orderId: Scalars['ID']['input'];
  claimId: Scalars['ID']['input'];
}>;

export type AcceptClaimWithReshipMutation = {
  __typename?: 'Mutation';
  acceptClaimWithReship?: any | null;
};

export type RemoveCsvMutationVariables = Exact<{
  saleId: Scalars['String']['input'];
  url: Scalars['String']['input'];
}>;

export type RemoveCsvMutation = {
  __typename?: 'Mutation';
  removeCsv?: string | null;
};

export type ConfirmCsvMutationVariables = Exact<{
  saleId: Scalars['String']['input'];
  url: Scalars['String']['input'];
}>;

export type ConfirmCsvMutation = {
  __typename?: 'Mutation';
  confirmCsv?: string | null;
};

export type UploadCsvFileMutationVariables = Exact<{
  saleId: Scalars['String']['input'];
  url: Scalars['String']['input'];
}>;

export type UploadCsvFileMutation = {
  __typename?: 'Mutation';
  uploadCsvFile?: string | null;
};

export type UpdateStockMutationVariables = Exact<{
  stockId: Scalars['String']['input'];
  saleId: Scalars['String']['input'];
  stock?: InputMaybe<Scalars['Int']['input']>;
}>;

export type UpdateStockMutation = {
  __typename?: 'Mutation';
  updateStock?: string | null;
};

export type UpdateWidgetMutationVariables = Exact<{
  id: Scalars['String']['input'];
  data: UpdateWidget;
}>;

export type UpdateWidgetMutation = {
  __typename?: 'Mutation';
  updateWidget?: string | null;
};

export type UpdateSellerMutationVariables = Exact<{
  updateSeller: UpdateSeller;
}>;

export type UpdateSellerMutation = {
  __typename?: 'Mutation';
  updateSeller?: string | null;
};

export type SetInfoAsUpToDateMutationVariables = Exact<{
  [key: string]: never;
}>;

export type SetInfoAsUpToDateMutation = {
  __typename?: 'Mutation';
  setInfoAsUpToDate?: string | null;
};

export type AcceptClaimOnDigitalItemsWithMessageMutationVariables = Exact<{
  orderId: Scalars['ID']['input'];
  claimId: Scalars['ID']['input'];
  message: Scalars['String']['input'];
}>;

export type AcceptClaimOnDigitalItemsWithMessageMutation = {
  __typename?: 'Mutation';
  acceptClaimOnDigitalItemsWithMessage: { __typename?: 'OrderV2'; id: string };
};

export type AcceptClaimOnDigitalItemsWithCodesMutationVariables = Exact<{
  orderId: Scalars['ID']['input'];
  claimId: Scalars['ID']['input'];
  codes: Array<NewDigitalCouponCodeInput> | NewDigitalCouponCodeInput;
}>;

export type AcceptClaimOnDigitalItemsWithCodesMutation = {
  __typename?: 'Mutation';
  acceptClaimOnDigitalItemsWithCodes: { __typename?: 'OrderV2'; id: string };
};

export type TranslateClaimMutationVariables = Exact<{
  claimId: Scalars['ID']['input'];
  to: Scalars['String']['input'];
}>;

export type TranslateClaimMutation = {
  __typename?: 'Mutation';
  translateClaim: {
    __typename?: 'TranslateResponse';
    originalLanguage?: string | null;
    translation: string;
  };
};

export type ResyncShopifyOrdersMutationVariables = Exact<{
  saleId: Scalars['String']['input'];
}>;

export type ResyncShopifyOrdersMutation = {
  __typename?: 'Mutation';
  resyncShopifyOrders?: number | null;
};

export type SetupShopifyCredentialsMutationVariables = Exact<{
  brandId: Scalars['String']['input'];
  data: ShopifyCredentialsInput;
}>;

export type SetupShopifyCredentialsMutation = {
  __typename?: 'Mutation';
  setupShopifyCredentials?: {
    __typename?: 'ShopifyData';
    token?: string | null;
    shop: string;
    isStockLive: boolean;
    sharedSecret?: string | null;
    carrierCode?: string | null;
    internationalCarrierCode?: string | null;
    isProductPriceKept: boolean;
    mondialRelayCarrierCode?: string | null;
  } | null;
};

export type UpdateShopifyInfoMutationVariables = Exact<{
  data: UpdateShopifyInfoInput;
}>;

export type UpdateShopifyInfoMutation = {
  __typename?: 'Mutation';
  updateShopifyInfo?: {
    __typename?: 'UpdatedShopifyData';
    isStockLive: boolean;
    isProductPriceKept: boolean;
    carrierCode?: string | null;
    internationalCarrierCode?: string | null;
    mondialRelayCarrierCode?: string | null;
  } | null;
};

export type InvoiceCreatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type InvoiceCreatedSubscription = {
  __typename?: 'Subscription';
  invoiceCreated: { __typename?: 'InvoiceCreated'; id: string };
};

export type InvoiceUpdatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type InvoiceUpdatedSubscription = {
  __typename?: 'Subscription';
  invoiceUpdated: { __typename?: 'InvoiceUpdated'; id: string };
};

export type OrderCreatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type OrderCreatedSubscription = {
  __typename?: 'Subscription';
  orderCreated: { __typename?: 'OrderCreated'; id: string };
};

export type OrderUpdatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type OrderUpdatedSubscription = {
  __typename?: 'Subscription';
  orderUpdated: { __typename?: 'OrderUpdated'; id: string };
};

export type WidgetUpdatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type WidgetUpdatedSubscription = {
  __typename?: 'Subscription';
  widgetUpdated?: {
    __typename?: 'Widget';
    id: string;
    type?: WidgetType | null;
    state?: WidgetState | null;
    results?: any | null;
    proposition?: any | null;
  } | null;
};

export type ProductStockUpdatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type ProductStockUpdatedSubscription = {
  __typename?: 'Subscription';
  productStockUpdated: {
    __typename?: 'ProductStockUpdated';
    productId: string;
    productVariantId: string;
    stock: number;
  };
};

export const WidgetFieldsFragmentDoc = `
    fragment WidgetFields on Widget {
  id
  type
  state
  results
  proposition
}
    `;
export const BrandDocument = `
    query brand {
  brand {
    id
    name
    store
    is_french
    is_autoentrepreneur
    contact_email
    contact_phone
    siret
    intracommunity_vat
    tax_id
    beneficiary_name
    account_number
    routing_code
    swift_code
    bank_address
    plugins {
      shopify
    }
    shopify {
      shop
      token
      sharedSecret
      isStockLive
      carrierCode
      internationalCarrierCode
      isProductPriceKept
      mondialRelayCarrierCode
    }
    iban
    contact_name
    address
    return_address
    shipping_country
    has_shopify
    shopify_url
    shipping_services
    max_delivery_delay
    min_delivery_delay
    delivery_services
    accept_contract
    need_refresh_info
    timezone
    serverTime
    sales {
      id
      start_at
      end_at
      logistics_manager
      selection_name
      widgets {
        ...WidgetFields
      }
      store
      currency
    }
  }
}
    ${WidgetFieldsFragmentDoc}`;

export const useBrandQuery = <TData = BrandQuery, TError = unknown>(
  variables?: BrandQueryVariables,
  options?: Omit<UseQueryOptions<BrandQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<BrandQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<BrandQuery, TError, TData>({
    queryKey: variables === undefined ? ['brand'] : ['brand', variables],
    queryFn: useFetchData<BrandQuery, BrandQueryVariables>(BrandDocument).bind(
      null,
      variables
    ),
    ...options,
  });
};

export const useSuspenseBrandQuery = <TData = BrandQuery, TError = unknown>(
  variables?: BrandQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<BrandQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<BrandQuery, TError, TData>['queryKey'];
  }
) => {
  return useSuspenseQuery<BrandQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['brandSuspense']
        : ['brandSuspense', variables],
    queryFn: useFetchData<BrandQuery, BrandQueryVariables>(BrandDocument).bind(
      null,
      variables
    ),
    ...options,
  });
};

export const useInfiniteBrandQuery = <
  TData = InfiniteData<BrandQuery>,
  TError = unknown,
>(
  variables: BrandQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<BrandQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<BrandQuery, TError, TData>['queryKey'];
  }
) => {
  const query = useFetchData<BrandQuery, BrandQueryVariables>(BrandDocument);
  return useInfiniteQuery<BrandQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey:
          (optionsQueryKey ?? variables === undefined)
            ? ['brand.infinite']
            : ['brand.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteBrandQuery = <
  TData = InfiniteData<BrandQuery>,
  TError = unknown,
>(
  variables: BrandQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<BrandQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      BrandQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<BrandQuery, BrandQueryVariables>(BrandDocument);
  return useSuspenseInfiniteQuery<BrandQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey:
          (optionsQueryKey ?? variables === undefined)
            ? ['brand.infiniteSuspense']
            : ['brand.infiniteSuspense', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const ApiKeyDocument = `
    query apiKey {
  brand {
    api_token
  }
}
    `;

export const useApiKeyQuery = <TData = ApiKeyQuery, TError = unknown>(
  variables?: ApiKeyQueryVariables,
  options?: Omit<UseQueryOptions<ApiKeyQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<ApiKeyQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<ApiKeyQuery, TError, TData>({
    queryKey: variables === undefined ? ['apiKey'] : ['apiKey', variables],
    queryFn: useFetchData<ApiKeyQuery, ApiKeyQueryVariables>(
      ApiKeyDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseApiKeyQuery = <TData = ApiKeyQuery, TError = unknown>(
  variables?: ApiKeyQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<ApiKeyQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<ApiKeyQuery, TError, TData>['queryKey'];
  }
) => {
  return useSuspenseQuery<ApiKeyQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['apiKeySuspense']
        : ['apiKeySuspense', variables],
    queryFn: useFetchData<ApiKeyQuery, ApiKeyQueryVariables>(
      ApiKeyDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteApiKeyQuery = <
  TData = InfiniteData<ApiKeyQuery>,
  TError = unknown,
>(
  variables: ApiKeyQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<ApiKeyQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<ApiKeyQuery, TError, TData>['queryKey'];
  }
) => {
  const query = useFetchData<ApiKeyQuery, ApiKeyQueryVariables>(ApiKeyDocument);
  return useInfiniteQuery<ApiKeyQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey:
          (optionsQueryKey ?? variables === undefined)
            ? ['apiKey.infinite']
            : ['apiKey.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteApiKeyQuery = <
  TData = InfiniteData<ApiKeyQuery>,
  TError = unknown,
>(
  variables: ApiKeyQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<ApiKeyQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      ApiKeyQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<ApiKeyQuery, ApiKeyQueryVariables>(ApiKeyDocument);
  return useSuspenseInfiniteQuery<ApiKeyQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey:
          (optionsQueryKey ?? variables === undefined)
            ? ['apiKey.infiniteSuspense']
            : ['apiKey.infiniteSuspense', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const StockDocument = `
    query stock($saleId: String!, $sort: ProductsSort) {
  products(saleId: $saleId, sort: $sort) {
    name
    id
    type
    url
    category
    images
    variants {
      images
      sku
      stock
      id
      gtin
      stock_id
      options
    }
  }
}
    `;

export const useStockQuery = <TData = StockQuery, TError = unknown>(
  variables: StockQueryVariables,
  options?: Omit<UseQueryOptions<StockQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<StockQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<StockQuery, TError, TData>({
    queryKey: ['stock', variables],
    queryFn: useFetchData<StockQuery, StockQueryVariables>(StockDocument).bind(
      null,
      variables
    ),
    ...options,
  });
};

export const useSuspenseStockQuery = <TData = StockQuery, TError = unknown>(
  variables: StockQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<StockQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<StockQuery, TError, TData>['queryKey'];
  }
) => {
  return useSuspenseQuery<StockQuery, TError, TData>({
    queryKey: ['stockSuspense', variables],
    queryFn: useFetchData<StockQuery, StockQueryVariables>(StockDocument).bind(
      null,
      variables
    ),
    ...options,
  });
};

export const useInfiniteStockQuery = <
  TData = InfiniteData<StockQuery>,
  TError = unknown,
>(
  variables: StockQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<StockQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<StockQuery, TError, TData>['queryKey'];
  }
) => {
  const query = useFetchData<StockQuery, StockQueryVariables>(StockDocument);
  return useInfiniteQuery<StockQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['stock.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteStockQuery = <
  TData = InfiniteData<StockQuery>,
  TError = unknown,
>(
  variables: StockQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<StockQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      StockQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<StockQuery, StockQueryVariables>(StockDocument);
  return useSuspenseInfiniteQuery<StockQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['stock.infiniteSuspense', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const StatsHistogramDocument = `
    query statsHistogram($saleId: NonEmptyString!) {
  statsHistogram(saleId: $saleId) {
    nb_distinct_orders
    date
    total_operation_ht
    total_operation_ttc
  }
}
    `;

export const useStatsHistogramQuery = <
  TData = StatsHistogramQuery,
  TError = unknown,
>(
  variables: StatsHistogramQueryVariables,
  options?: Omit<
    UseQueryOptions<StatsHistogramQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<StatsHistogramQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<StatsHistogramQuery, TError, TData>({
    queryKey: ['statsHistogram', variables],
    queryFn: useFetchData<StatsHistogramQuery, StatsHistogramQueryVariables>(
      StatsHistogramDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseStatsHistogramQuery = <
  TData = StatsHistogramQuery,
  TError = unknown,
>(
  variables: StatsHistogramQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<StatsHistogramQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      StatsHistogramQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<StatsHistogramQuery, TError, TData>({
    queryKey: ['statsHistogramSuspense', variables],
    queryFn: useFetchData<StatsHistogramQuery, StatsHistogramQueryVariables>(
      StatsHistogramDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteStatsHistogramQuery = <
  TData = InfiniteData<StatsHistogramQuery>,
  TError = unknown,
>(
  variables: StatsHistogramQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<StatsHistogramQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      StatsHistogramQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<StatsHistogramQuery, StatsHistogramQueryVariables>(
    StatsHistogramDocument
  );
  return useInfiniteQuery<StatsHistogramQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['statsHistogram.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteStatsHistogramQuery = <
  TData = InfiniteData<StatsHistogramQuery>,
  TError = unknown,
>(
  variables: StatsHistogramQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<StatsHistogramQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      StatsHistogramQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<StatsHistogramQuery, StatsHistogramQueryVariables>(
    StatsHistogramDocument
  );
  return useSuspenseInfiniteQuery<StatsHistogramQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? [
          'statsHistogram.infiniteSuspense',
          variables,
        ],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const RatingsDocument = `
    query ratings($saleId: String!, $offset: Int, $onlyWithMessage: Boolean, $filter: Int) {
  ratings(
    saleId: $saleId
    offset: $offset
    onlyWithMessage: $onlyWithMessage
    filter: $filter
  ) {
    updatedAt
    nodes {
      rate
      rate_updated_at
      url
      products
      name
      avatar
      rate_message
      order_id
    }
    pageInfo {
      hasNextPage
    }
    totalCount
    totalPages
  }
}
    `;

export const useRatingsQuery = <TData = RatingsQuery, TError = unknown>(
  variables: RatingsQueryVariables,
  options?: Omit<UseQueryOptions<RatingsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<RatingsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<RatingsQuery, TError, TData>({
    queryKey: ['ratings', variables],
    queryFn: useFetchData<RatingsQuery, RatingsQueryVariables>(
      RatingsDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseRatingsQuery = <TData = RatingsQuery, TError = unknown>(
  variables: RatingsQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<RatingsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<RatingsQuery, TError, TData>['queryKey'];
  }
) => {
  return useSuspenseQuery<RatingsQuery, TError, TData>({
    queryKey: ['ratingsSuspense', variables],
    queryFn: useFetchData<RatingsQuery, RatingsQueryVariables>(
      RatingsDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteRatingsQuery = <
  TData = InfiniteData<RatingsQuery>,
  TError = unknown,
>(
  variables: RatingsQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<RatingsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<RatingsQuery, TError, TData>['queryKey'];
  }
) => {
  const query = useFetchData<RatingsQuery, RatingsQueryVariables>(
    RatingsDocument
  );
  return useInfiniteQuery<RatingsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['ratings.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteRatingsQuery = <
  TData = InfiniteData<RatingsQuery>,
  TError = unknown,
>(
  variables: RatingsQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<RatingsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      RatingsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<RatingsQuery, RatingsQueryVariables>(
    RatingsDocument
  );
  return useSuspenseInfiniteQuery<RatingsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['ratings.infiniteSuspense', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const TopProductsStatsDocument = `
    query topProductsStats($saleId: String!, $optionKey: String) {
  topProductsStats(saleId: $saleId, optionKey: $optionKey) {
    available_option_keys
    current_option_key
    products {
      group_id
      product_id
      product_name
      image_url
      options
      purchased_count
      total_amount
      variants {
        product_id
        variant_id
        product_name
        image_url
        option_value
        options
        purchased_count
        price
        total_amount
      }
    }
  }
}
    `;

export const useTopProductsStatsQuery = <
  TData = TopProductsStatsQuery,
  TError = unknown,
>(
  variables: TopProductsStatsQueryVariables,
  options?: Omit<
    UseQueryOptions<TopProductsStatsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      TopProductsStatsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<TopProductsStatsQuery, TError, TData>({
    queryKey: ['topProductsStats', variables],
    queryFn: useFetchData<
      TopProductsStatsQuery,
      TopProductsStatsQueryVariables
    >(TopProductsStatsDocument).bind(null, variables),
    ...options,
  });
};

export const useSuspenseTopProductsStatsQuery = <
  TData = TopProductsStatsQuery,
  TError = unknown,
>(
  variables: TopProductsStatsQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<TopProductsStatsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      TopProductsStatsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<TopProductsStatsQuery, TError, TData>({
    queryKey: ['topProductsStatsSuspense', variables],
    queryFn: useFetchData<
      TopProductsStatsQuery,
      TopProductsStatsQueryVariables
    >(TopProductsStatsDocument).bind(null, variables),
    ...options,
  });
};

export const useInfiniteTopProductsStatsQuery = <
  TData = InfiniteData<TopProductsStatsQuery>,
  TError = unknown,
>(
  variables: TopProductsStatsQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<TopProductsStatsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      TopProductsStatsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<
    TopProductsStatsQuery,
    TopProductsStatsQueryVariables
  >(TopProductsStatsDocument);
  return useInfiniteQuery<TopProductsStatsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['topProductsStats.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteTopProductsStatsQuery = <
  TData = InfiniteData<TopProductsStatsQuery>,
  TError = unknown,
>(
  variables: TopProductsStatsQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<TopProductsStatsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      TopProductsStatsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<
    TopProductsStatsQuery,
    TopProductsStatsQueryVariables
  >(TopProductsStatsDocument);
  return useSuspenseInfiniteQuery<TopProductsStatsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? [
          'topProductsStats.infiniteSuspense',
          variables,
        ],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const StatsDocument = `
    query stats($saleId: NonEmptyString!) {
  statsV2(saleId: $saleId) {
    rateCountAggByRates {
      rate
      count
    }
    rateCount
    rateAvg
    productSoldCount
    orderCount
    totalBillingHt
    totalBillingTtc
    totalTurnoverHt
    totalTurnoverTtc
    isSubjectToVat
  }
}
    `;

export const useStatsQuery = <TData = StatsQuery, TError = unknown>(
  variables: StatsQueryVariables,
  options?: Omit<UseQueryOptions<StatsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<StatsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<StatsQuery, TError, TData>({
    queryKey: ['stats', variables],
    queryFn: useFetchData<StatsQuery, StatsQueryVariables>(StatsDocument).bind(
      null,
      variables
    ),
    ...options,
  });
};

export const useSuspenseStatsQuery = <TData = StatsQuery, TError = unknown>(
  variables: StatsQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<StatsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<StatsQuery, TError, TData>['queryKey'];
  }
) => {
  return useSuspenseQuery<StatsQuery, TError, TData>({
    queryKey: ['statsSuspense', variables],
    queryFn: useFetchData<StatsQuery, StatsQueryVariables>(StatsDocument).bind(
      null,
      variables
    ),
    ...options,
  });
};

export const useInfiniteStatsQuery = <
  TData = InfiniteData<StatsQuery>,
  TError = unknown,
>(
  variables: StatsQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<StatsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<StatsQuery, TError, TData>['queryKey'];
  }
) => {
  const query = useFetchData<StatsQuery, StatsQueryVariables>(StatsDocument);
  return useInfiniteQuery<StatsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['stats.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteStatsQuery = <
  TData = InfiniteData<StatsQuery>,
  TError = unknown,
>(
  variables: StatsQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<StatsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      StatsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<StatsQuery, StatsQueryVariables>(StatsDocument);
  return useSuspenseInfiniteQuery<StatsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['stats.infiniteSuspense', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const ReturnsDocument = `
    query returns($saleId: String!) {
  returns(saleId: $saleId) {
    updatedAt
    total_orders
    total_sum
    percentage
    returns {
      sum
      code
    }
    products {
      image
      description
      options
      sum
    }
  }
}
    `;

export const useReturnsQuery = <TData = ReturnsQuery, TError = unknown>(
  variables: ReturnsQueryVariables,
  options?: Omit<UseQueryOptions<ReturnsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<ReturnsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<ReturnsQuery, TError, TData>({
    queryKey: ['returns', variables],
    queryFn: useFetchData<ReturnsQuery, ReturnsQueryVariables>(
      ReturnsDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseReturnsQuery = <TData = ReturnsQuery, TError = unknown>(
  variables: ReturnsQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<ReturnsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<ReturnsQuery, TError, TData>['queryKey'];
  }
) => {
  return useSuspenseQuery<ReturnsQuery, TError, TData>({
    queryKey: ['returnsSuspense', variables],
    queryFn: useFetchData<ReturnsQuery, ReturnsQueryVariables>(
      ReturnsDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteReturnsQuery = <
  TData = InfiniteData<ReturnsQuery>,
  TError = unknown,
>(
  variables: ReturnsQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<ReturnsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<ReturnsQuery, TError, TData>['queryKey'];
  }
) => {
  const query = useFetchData<ReturnsQuery, ReturnsQueryVariables>(
    ReturnsDocument
  );
  return useInfiniteQuery<ReturnsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['returns.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteReturnsQuery = <
  TData = InfiniteData<ReturnsQuery>,
  TError = unknown,
>(
  variables: ReturnsQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<ReturnsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      ReturnsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<ReturnsQuery, ReturnsQueryVariables>(
    ReturnsDocument
  );
  return useSuspenseInfiniteQuery<ReturnsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['returns.infiniteSuspense', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const UploadUrlDocument = `
    query uploadUrl($upload: Upload!) {
  getUploadUrl(upload: $upload)
}
    `;

export const useUploadUrlQuery = <TData = UploadUrlQuery, TError = unknown>(
  variables: UploadUrlQueryVariables,
  options?: Omit<UseQueryOptions<UploadUrlQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<UploadUrlQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<UploadUrlQuery, TError, TData>({
    queryKey: ['uploadUrl', variables],
    queryFn: useFetchData<UploadUrlQuery, UploadUrlQueryVariables>(
      UploadUrlDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseUploadUrlQuery = <
  TData = UploadUrlQuery,
  TError = unknown,
>(
  variables: UploadUrlQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<UploadUrlQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      UploadUrlQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<UploadUrlQuery, TError, TData>({
    queryKey: ['uploadUrlSuspense', variables],
    queryFn: useFetchData<UploadUrlQuery, UploadUrlQueryVariables>(
      UploadUrlDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useInfiniteUploadUrlQuery = <
  TData = InfiniteData<UploadUrlQuery>,
  TError = unknown,
>(
  variables: UploadUrlQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<UploadUrlQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      UploadUrlQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<UploadUrlQuery, UploadUrlQueryVariables>(
    UploadUrlDocument
  );
  return useInfiniteQuery<UploadUrlQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['uploadUrl.infinite', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteUploadUrlQuery = <
  TData = InfiniteData<UploadUrlQuery>,
  TError = unknown,
>(
  variables: UploadUrlQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<UploadUrlQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      UploadUrlQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<UploadUrlQuery, UploadUrlQueryVariables>(
    UploadUrlDocument
  );
  return useSuspenseInfiniteQuery<UploadUrlQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? ['uploadUrl.infiniteSuspense', variables],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const VerifyShopifyCredentialsDocument = `
    query verifyShopifyCredentials($url: String!, $accessToken: String) {
  verifyShopifyCredentials(url: $url, accessToken: $accessToken) {
    isValidAccessToken
    isValidUrl
  }
}
    `;

export const useVerifyShopifyCredentialsQuery = <
  TData = VerifyShopifyCredentialsQuery,
  TError = unknown,
>(
  variables: VerifyShopifyCredentialsQueryVariables,
  options?: Omit<
    UseQueryOptions<VerifyShopifyCredentialsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      VerifyShopifyCredentialsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<VerifyShopifyCredentialsQuery, TError, TData>({
    queryKey: ['verifyShopifyCredentials', variables],
    queryFn: useFetchData<
      VerifyShopifyCredentialsQuery,
      VerifyShopifyCredentialsQueryVariables
    >(VerifyShopifyCredentialsDocument).bind(null, variables),
    ...options,
  });
};

export const useSuspenseVerifyShopifyCredentialsQuery = <
  TData = VerifyShopifyCredentialsQuery,
  TError = unknown,
>(
  variables: VerifyShopifyCredentialsQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<VerifyShopifyCredentialsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      VerifyShopifyCredentialsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<VerifyShopifyCredentialsQuery, TError, TData>({
    queryKey: ['verifyShopifyCredentialsSuspense', variables],
    queryFn: useFetchData<
      VerifyShopifyCredentialsQuery,
      VerifyShopifyCredentialsQueryVariables
    >(VerifyShopifyCredentialsDocument).bind(null, variables),
    ...options,
  });
};

export const useInfiniteVerifyShopifyCredentialsQuery = <
  TData = InfiniteData<VerifyShopifyCredentialsQuery>,
  TError = unknown,
>(
  variables: VerifyShopifyCredentialsQueryVariables,
  options: Omit<
    UseInfiniteQueryOptions<VerifyShopifyCredentialsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseInfiniteQueryOptions<
      VerifyShopifyCredentialsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<
    VerifyShopifyCredentialsQuery,
    VerifyShopifyCredentialsQueryVariables
  >(VerifyShopifyCredentialsDocument);
  return useInfiniteQuery<VerifyShopifyCredentialsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? [
          'verifyShopifyCredentials.infinite',
          variables,
        ],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const useSuspenseInfiniteVerifyShopifyCredentialsQuery = <
  TData = InfiniteData<VerifyShopifyCredentialsQuery>,
  TError = unknown,
>(
  variables: VerifyShopifyCredentialsQueryVariables,
  options: Omit<
    UseSuspenseInfiniteQueryOptions<
      VerifyShopifyCredentialsQuery,
      TError,
      TData
    >,
    'queryKey'
  > & {
    queryKey?: UseSuspenseInfiniteQueryOptions<
      VerifyShopifyCredentialsQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  const query = useFetchData<
    VerifyShopifyCredentialsQuery,
    VerifyShopifyCredentialsQueryVariables
  >(VerifyShopifyCredentialsDocument);
  return useSuspenseInfiniteQuery<VerifyShopifyCredentialsQuery, TError, TData>(
    (() => {
      const { queryKey: optionsQueryKey, ...restOptions } = options;
      return {
        queryKey: optionsQueryKey ?? [
          'verifyShopifyCredentials.infiniteSuspense',
          variables,
        ],
        queryFn: (metaData) =>
          query({ ...variables, ...(metaData.pageParam ?? {}) }),
        ...restOptions,
      };
    })()
  );
};

export const AcceptClaimWithRefundDocument = `
    mutation acceptClaimWithRefund($orderId: ID!, $claimId: ID!) {
  acceptClaimWithRefund(orderId: $orderId, claimId: $claimId)
}
    `;

export const useAcceptClaimWithRefundMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    AcceptClaimWithRefundMutation,
    TError,
    AcceptClaimWithRefundMutationVariables,
    TContext
  >
) => {
  return useMutation<
    AcceptClaimWithRefundMutation,
    TError,
    AcceptClaimWithRefundMutationVariables,
    TContext
  >({
    mutationKey: ['acceptClaimWithRefund'],
    mutationFn: useFetchData<
      AcceptClaimWithRefundMutation,
      AcceptClaimWithRefundMutationVariables
    >(AcceptClaimWithRefundDocument),
    ...options,
  });
};

export const AcceptClaimWithReshipDocument = `
    mutation acceptClaimWithReship($orderId: ID!, $claimId: ID!) {
  acceptClaimWithReship(orderId: $orderId, claimId: $claimId)
}
    `;

export const useAcceptClaimWithReshipMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    AcceptClaimWithReshipMutation,
    TError,
    AcceptClaimWithReshipMutationVariables,
    TContext
  >
) => {
  return useMutation<
    AcceptClaimWithReshipMutation,
    TError,
    AcceptClaimWithReshipMutationVariables,
    TContext
  >({
    mutationKey: ['acceptClaimWithReship'],
    mutationFn: useFetchData<
      AcceptClaimWithReshipMutation,
      AcceptClaimWithReshipMutationVariables
    >(AcceptClaimWithReshipDocument),
    ...options,
  });
};

export const RemoveCsvDocument = `
    mutation removeCsv($saleId: String!, $url: String!) {
  removeCsv(saleId: $saleId, url: $url)
}
    `;

export const useRemoveCsvMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    RemoveCsvMutation,
    TError,
    RemoveCsvMutationVariables,
    TContext
  >
) => {
  return useMutation<
    RemoveCsvMutation,
    TError,
    RemoveCsvMutationVariables,
    TContext
  >({
    mutationKey: ['removeCsv'],
    mutationFn: useFetchData<RemoveCsvMutation, RemoveCsvMutationVariables>(
      RemoveCsvDocument
    ),
    ...options,
  });
};

export const ConfirmCsvDocument = `
    mutation confirmCsv($saleId: String!, $url: String!) {
  confirmCsv(saleId: $saleId, url: $url)
}
    `;

export const useConfirmCsvMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ConfirmCsvMutation,
    TError,
    ConfirmCsvMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ConfirmCsvMutation,
    TError,
    ConfirmCsvMutationVariables,
    TContext
  >({
    mutationKey: ['confirmCsv'],
    mutationFn: useFetchData<ConfirmCsvMutation, ConfirmCsvMutationVariables>(
      ConfirmCsvDocument
    ),
    ...options,
  });
};

export const UploadCsvFileDocument = `
    mutation uploadCsvFile($saleId: String!, $url: String!) {
  uploadCsvFile(saleId: $saleId, url: $url)
}
    `;

export const useUploadCsvFileMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UploadCsvFileMutation,
    TError,
    UploadCsvFileMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UploadCsvFileMutation,
    TError,
    UploadCsvFileMutationVariables,
    TContext
  >({
    mutationKey: ['uploadCsvFile'],
    mutationFn: useFetchData<
      UploadCsvFileMutation,
      UploadCsvFileMutationVariables
    >(UploadCsvFileDocument),
    ...options,
  });
};

export const UpdateStockDocument = `
    mutation updateStock($stockId: String!, $saleId: String!, $stock: Int) {
  updateStock(stockId: $stockId, saleId: $saleId, stock: $stock)
}
    `;

export const useUpdateStockMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateStockMutation,
    TError,
    UpdateStockMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateStockMutation,
    TError,
    UpdateStockMutationVariables,
    TContext
  >({
    mutationKey: ['updateStock'],
    mutationFn: useFetchData<UpdateStockMutation, UpdateStockMutationVariables>(
      UpdateStockDocument
    ),
    ...options,
  });
};

export const UpdateWidgetDocument = `
    mutation updateWidget($id: String!, $data: UpdateWidget!) {
  updateWidget(id: $id, data: $data)
}
    `;

export const useUpdateWidgetMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateWidgetMutation,
    TError,
    UpdateWidgetMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateWidgetMutation,
    TError,
    UpdateWidgetMutationVariables,
    TContext
  >({
    mutationKey: ['updateWidget'],
    mutationFn: useFetchData<
      UpdateWidgetMutation,
      UpdateWidgetMutationVariables
    >(UpdateWidgetDocument),
    ...options,
  });
};

export const UpdateSellerDocument = `
    mutation updateSeller($updateSeller: UpdateSeller!) {
  updateSeller(updateSeller: $updateSeller)
}
    `;

export const useUpdateSellerMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateSellerMutation,
    TError,
    UpdateSellerMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateSellerMutation,
    TError,
    UpdateSellerMutationVariables,
    TContext
  >({
    mutationKey: ['updateSeller'],
    mutationFn: useFetchData<
      UpdateSellerMutation,
      UpdateSellerMutationVariables
    >(UpdateSellerDocument),
    ...options,
  });
};

export const SetInfoAsUpToDateDocument = `
    mutation setInfoAsUpToDate {
  setInfoAsUpToDate
}
    `;

export const useSetInfoAsUpToDateMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    SetInfoAsUpToDateMutation,
    TError,
    SetInfoAsUpToDateMutationVariables,
    TContext
  >
) => {
  return useMutation<
    SetInfoAsUpToDateMutation,
    TError,
    SetInfoAsUpToDateMutationVariables,
    TContext
  >({
    mutationKey: ['setInfoAsUpToDate'],
    mutationFn: useFetchData<
      SetInfoAsUpToDateMutation,
      SetInfoAsUpToDateMutationVariables
    >(SetInfoAsUpToDateDocument),
    ...options,
  });
};

export const AcceptClaimOnDigitalItemsWithMessageDocument = `
    mutation acceptClaimOnDigitalItemsWithMessage($orderId: ID!, $claimId: ID!, $message: String!) {
  acceptClaimOnDigitalItemsWithMessage(
    orderId: $orderId
    claimId: $claimId
    message: $message
  ) {
    id
  }
}
    `;

export const useAcceptClaimOnDigitalItemsWithMessageMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    AcceptClaimOnDigitalItemsWithMessageMutation,
    TError,
    AcceptClaimOnDigitalItemsWithMessageMutationVariables,
    TContext
  >
) => {
  return useMutation<
    AcceptClaimOnDigitalItemsWithMessageMutation,
    TError,
    AcceptClaimOnDigitalItemsWithMessageMutationVariables,
    TContext
  >({
    mutationKey: ['acceptClaimOnDigitalItemsWithMessage'],
    mutationFn: useFetchData<
      AcceptClaimOnDigitalItemsWithMessageMutation,
      AcceptClaimOnDigitalItemsWithMessageMutationVariables
    >(AcceptClaimOnDigitalItemsWithMessageDocument),
    ...options,
  });
};

export const AcceptClaimOnDigitalItemsWithCodesDocument = `
    mutation acceptClaimOnDigitalItemsWithCodes($orderId: ID!, $claimId: ID!, $codes: [NewDigitalCouponCodeInput!]!) {
  acceptClaimOnDigitalItemsWithCodes(
    orderId: $orderId
    claimId: $claimId
    codes: $codes
  ) {
    id
  }
}
    `;

export const useAcceptClaimOnDigitalItemsWithCodesMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    AcceptClaimOnDigitalItemsWithCodesMutation,
    TError,
    AcceptClaimOnDigitalItemsWithCodesMutationVariables,
    TContext
  >
) => {
  return useMutation<
    AcceptClaimOnDigitalItemsWithCodesMutation,
    TError,
    AcceptClaimOnDigitalItemsWithCodesMutationVariables,
    TContext
  >({
    mutationKey: ['acceptClaimOnDigitalItemsWithCodes'],
    mutationFn: useFetchData<
      AcceptClaimOnDigitalItemsWithCodesMutation,
      AcceptClaimOnDigitalItemsWithCodesMutationVariables
    >(AcceptClaimOnDigitalItemsWithCodesDocument),
    ...options,
  });
};

export const TranslateClaimDocument = `
    mutation translateClaim($claimId: ID!, $to: String!) {
  translateClaim(claimId: $claimId, to: $to) {
    originalLanguage
    translation
  }
}
    `;

export const useTranslateClaimMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    TranslateClaimMutation,
    TError,
    TranslateClaimMutationVariables,
    TContext
  >
) => {
  return useMutation<
    TranslateClaimMutation,
    TError,
    TranslateClaimMutationVariables,
    TContext
  >({
    mutationKey: ['translateClaim'],
    mutationFn: useFetchData<
      TranslateClaimMutation,
      TranslateClaimMutationVariables
    >(TranslateClaimDocument),
    ...options,
  });
};

export const ResyncShopifyOrdersDocument = `
    mutation resyncShopifyOrders($saleId: String!) {
  resyncShopifyOrders(saleId: $saleId)
}
    `;

export const useResyncShopifyOrdersMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    ResyncShopifyOrdersMutation,
    TError,
    ResyncShopifyOrdersMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ResyncShopifyOrdersMutation,
    TError,
    ResyncShopifyOrdersMutationVariables,
    TContext
  >({
    mutationKey: ['resyncShopifyOrders'],
    mutationFn: useFetchData<
      ResyncShopifyOrdersMutation,
      ResyncShopifyOrdersMutationVariables
    >(ResyncShopifyOrdersDocument),
    ...options,
  });
};

export const SetupShopifyCredentialsDocument = `
    mutation setupShopifyCredentials($brandId: String!, $data: ShopifyCredentialsInput!) {
  setupShopifyCredentials(brandId: $brandId, data: $data) {
    token
    shop
    isStockLive
    sharedSecret
    carrierCode
    internationalCarrierCode
    isProductPriceKept
    mondialRelayCarrierCode
  }
}
    `;

export const useSetupShopifyCredentialsMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    SetupShopifyCredentialsMutation,
    TError,
    SetupShopifyCredentialsMutationVariables,
    TContext
  >
) => {
  return useMutation<
    SetupShopifyCredentialsMutation,
    TError,
    SetupShopifyCredentialsMutationVariables,
    TContext
  >({
    mutationKey: ['setupShopifyCredentials'],
    mutationFn: useFetchData<
      SetupShopifyCredentialsMutation,
      SetupShopifyCredentialsMutationVariables
    >(SetupShopifyCredentialsDocument),
    ...options,
  });
};

export const UpdateShopifyInfoDocument = `
    mutation updateShopifyInfo($data: UpdateShopifyInfoInput!) {
  updateShopifyInfo(data: $data) {
    isStockLive
    isProductPriceKept
    carrierCode
    internationalCarrierCode
    mondialRelayCarrierCode
  }
}
    `;

export const useUpdateShopifyInfoMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateShopifyInfoMutation,
    TError,
    UpdateShopifyInfoMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateShopifyInfoMutation,
    TError,
    UpdateShopifyInfoMutationVariables,
    TContext
  >({
    mutationKey: ['updateShopifyInfo'],
    mutationFn: useFetchData<
      UpdateShopifyInfoMutation,
      UpdateShopifyInfoMutationVariables
    >(UpdateShopifyInfoDocument),
    ...options,
  });
};

export const InvoiceCreatedDocument = `
    subscription invoiceCreated {
  invoiceCreated {
    id
  }
}
    `;
export const InvoiceUpdatedDocument = `
    subscription invoiceUpdated {
  invoiceUpdated {
    id
  }
}
    `;
export const OrderCreatedDocument = `
    subscription orderCreated {
  orderCreated {
    id
  }
}
    `;
export const OrderUpdatedDocument = `
    subscription orderUpdated {
  orderUpdated {
    id
  }
}
    `;
export const WidgetUpdatedDocument = `
    subscription widgetUpdated {
  widgetUpdated {
    ...WidgetFields
  }
}
    ${WidgetFieldsFragmentDoc}`;
export const ProductStockUpdatedDocument = `
    subscription productStockUpdated {
  productStockUpdated {
    productId
    productVariantId
    stock
  }
}
    `;
